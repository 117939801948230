import CssClasses from '../common/cssClasses';
import Element_Ids from '../common/elementIds';
import TOKENS from '../common/tokens';
import OPTIONS_TOKENS from '../common/optionsTokens';

function imageLeftAddon() {
  return `<div class="${CssClasses.addonBox} ${CssClasses.imageLeft}" id="${Element_Ids.addOnItemContainer}${TOKENS.addOnProductHandle}">
              <div class="${CssClasses.col1}">
                  <span class="product-single__title upsell-font-color ${CssClasses.title}">${OPTIONS_TOKENS.titleSnippet}</span>
                  <div>
                        <a href="${TOKENS.addOnSlug}" target="_blank" class="upsell-addon-details ${OPTIONS_TOKENS.seeDetails}">${TOKENS.seeDetailsText}</a>   
                  </div>
              </div>
              <div class="${CssClasses.col2}">
                    <div>
                      <img src="${TOKENS.img}" class="${OPTIONS_TOKENS.imageClassName}">                    
                    </div>
                    <div class="${CssClasses.col3}" style="width: 100%;">
                      ${OPTIONS_TOKENS.variantDropdown}               
                      ${OPTIONS_TOKENS.addToCartInput}                     
                    </div>                
              </div>
              <div class=${CssClasses.description} id="${Element_Ids.descBlockContainer}${TOKENS.addOnProductHandle}">
                 <div>${TOKENS.description}</div>
                 <div class="${CssClasses.availableWith}">
                    ${TOKENS.availableWith}
                 </div>
              </div>
          </div>`;
}

export default imageLeftAddon;
