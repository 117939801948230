import createNativeToast from '../nativeToast';

export default function showError(xhr, options, errMsg) {
  let msg = errMsg || 'Sorry errors occurred in adding Add-Ons to the cart.';

  if (xhr.status === 422) {
    try {
      const contentType = xhr.getResponseHeader('Content-Type');
      if (contentType && contentType.includes('text/html')) {
        const regEx = /<h3>What happened\?<\/h3>\s.+?<div class="content--desc">(.+?)<\/div>/gim;
        const responseHtml = xhr.responseText.replace(/(\r\n|\n|\r)/gm, '');
        if (responseHtml) {
          const matches = responseHtml.match(regEx);
          if (matches && matches.length > 0) {
            msg = matches[0]
              .toLowerCase()
              .replace('<h3>what happened?</h3>', '')
              .replace('<div class="content--desc">', '')
              .replace('</div>', '')
              .trim();
          }
        }
      } else {
        msg = 'One of the selected Add-ons is out of stock.';
      }
    } catch (err) {
      msg = 'One of the selected Add-ons is out of stock.';
    }
  }

  if (xhr.responseJSON && xhr.responseJSON.description) {
    msg = xhr.responseJSON.description;
  }

  if (xhr.status === 422 && options.soldOutMsg) {
    msg = options.soldOutMsg;
  }
  createNativeToast(function() {
    nativeToast({
      message: msg,
      type: 'error',
      position: options.errPosition || 'south-east',
      timeout: 5000,
      rounded: options.errToast,
      edge: !options.errToast
    });
  });
}

export function showDefaultError(msg, options) {
  createNativeToast(function() {
    nativeToast({
      message: msg,
      type: 'error',
      position: options.errPosition || 'south-east',
      timeout: 5000,
      rounded: options.errToast,
      edge: !options.errToast
    });
  });
}
