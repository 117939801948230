import errorHandler from '../errorHandler';

export function xhrIntercept(urlmatch, callback) {
  let send = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function() {
    this.addEventListener(
      'readystatechange',
      function() {
        try {
          if (
            this.responseURL &&
            this.responseURL.includes(urlmatch) &&
            this.readyState === 4
          ) {
            callback(this);
          }
        } catch (err) {
          errorHandler(err);
        }
      },
      false
    );
    send.apply(this, arguments);
  };
}
