import { BOX_TYPE, LAYOUT_TYPE, ADDON_TYPE } from './domain';
import Element_Ids from './template/common/elementIds';
import TOKENS from './template/common/tokens';
import STYLE_TOKENS from './template/common/styleTokens';
import CssClasses from './template/common/cssClasses';
import OPTIONS_TOKENS from './template/common/optionsTokens';
import styles from './template/common/styles';
import imageLeftAddon from './template/components/imageLeftAddon';
import placeholder from './template/components/placeholder';
import {
  getAppProps,
  getValidClassNames,
  isDawnTheme,
  safeEncodeURIComponent
} from './utils';

export function isSoldOut(addOnDetails) {
  // TODO: inventory_quantity can return zero and negative values

  return false;
}

const theme = IS_SHOPIFY_ENV
  ? Shopify && Shopify.theme
    ? Shopify.theme.name.trim().replace(new RegExp(' ', 'g'), '_')
    : 'upsell-theme-notfound'
  : '';

const QTY_BOX_PLACEHOLDER = 'QTY_BOX_PLACEHOLDER';
const QTY_BOX_HTML = `<div class="${CssClasses.qtyWrapper}"> <div class="${CssClasses.qtyLabel}">${TOKENS.qtyLabel}</div> 
    <div class="${CssClasses.qtyBoxInput}">${QTY_BOX_PLACEHOLDER}</div>
</div>`;

export const FLEX_DISPLAY = 'display:flex;flex-wrap: wrap;';

export function productPageContainer(layoutType, productHandle) {
  return container('upsell-productpage', '', layoutType, productHandle);
}

export function cartPageContainer(layoutType) {
  return container('upsell-cartpage', CssClasses.cartPageContainer, layoutType);
}

export function addOn(boxType) {
  const descHtml = `
    <div class=${CssClasses.description} id="${Element_Ids.descBlockContainer}${TOKENS.addOnProductHandle}">
        <div>${TOKENS.description}</div>
        <div class="${CssClasses.availableWith}">
            ${TOKENS.availableWith}
        </div>
    </div>
  `;

  let boxClass = CssClasses.defaultAddOnBox;
  if (boxType === BOX_TYPE.ImageTop) {
    boxClass = CssClasses.imageTop;

    return `
          <div class="${CssClasses.addonBox}">
            <div class="${boxClass}" id="${Element_Ids.addOnItemContainer}${TOKENS.addOnProductHandle}">
                <div class="${CssClasses.col1}">
                    <img src="${TOKENS.img}" class="${OPTIONS_TOKENS.imageClassName}">
                </div>
                <div class="${CssClasses.col2}">
                    <span class="product-single__title upsell-font-color ${CssClasses.title} ${CssClasses.link}">
                        ${OPTIONS_TOKENS.titleSnippet}
                    </span>
                    <br/>
                    <a href="${TOKENS.addOnSlug}" target="_blank" class="upsell-addon-details ${OPTIONS_TOKENS.seeDetails} ${CssClasses.link}">
                        ${TOKENS.seeDetailsText}
                    </a>
                </div>
                <div class="${CssClasses.col3}">
                      ${OPTIONS_TOKENS.variantDropdown}
                      ${OPTIONS_TOKENS.addToCartInput}
                </div>
            </div>
            ${descHtml}
          </div>`;
  } else if (boxType === BOX_TYPE.ImageLeft) {
    return imageLeftAddon();
  }

  return `<div class="${CssClasses.addonBox}">
            <div class="${boxClass}" id="${Element_Ids.addOnItemContainer}${TOKENS.addOnProductHandle}">
                <div class="${CssClasses.col1}">
                    <img src="${TOKENS.img}" class="${OPTIONS_TOKENS.imageClassName}">
                </div>
                <div class="${CssClasses.col2}">
                    <span class="product-single__title upsell-font-color ${CssClasses.title} ${CssClasses.link}">
                        ${OPTIONS_TOKENS.titleSnippet}
                    </span>
                    <br/>
                    ${OPTIONS_TOKENS.variantDropdown}
                    <a href="${TOKENS.addOnSlug}" target="_blank" class="upsell-addon-details ${OPTIONS_TOKENS.seeDetails} ${CssClasses.link}">
                        ${TOKENS.seeDetailsText}
                    </a>
                </div>
                <div class="${CssClasses.col3}">
                      ${OPTIONS_TOKENS.addToCartInput}
                </div>
            </div>
            ${descHtml}
          </div>`;
}

function renderWithPrice(addOnDetails, element, type) {
  const showSold = isSoldOut(addOnDetails);

  let ui = element;
  let isDrop = false;

  if (
    type === ADDON_TYPE.Dropdown ||
    type === ADDON_TYPE.ButtonQtyBox ||
    type === ADDON_TYPE.QtyBox
  ) {
    isDrop = true;
  }

  const styles = {
    wrap: `
      display: flex;
      ${isDrop ? 'align-items: center;' : ''}
      ${showSold ? 'flex-direction: column' : ''}
    `,
    price: `
      display: flex;
      flex-direction: column;
    `,
    ui: `
      margin-left: 4px;
    `,
    uiButton: `
      margin-left: 4px;
    `
  };

  let styleSelected = styles.ui;

  if (type !== ADDON_TYPE.Checkbox) {
    styleSelected = styles.uiButton;
  }

  if (showSold) {
    ui = sold();
    styleSelected = '';
  }

  return `
    <div style="${styles.wrap}">
          <label 
            class="${Element_Ids.price} ${Element_Ids.priceTargetElement}" 
            style="${styles.price}"
          >
            ${TOKENS.priceElement}
          </label>
          <div class="${Element_Ids.uiBlockWrapper}" style="${styleSelected}">
            ${ui}     
          </div>
    </div>`;
}

export function button(data) {
  const {
    original,
    isGroupedVariant,
    productHandle,
    qtyType,
    addOnDetails
  } = data;
  const className = getValidClassNames(original.className);
  const style = original.style ? original.style.cssText : '' || '';

  const qtyBox =
    qtyType === ADDON_TYPE.QtyBox
      ? `<div class="${CssClasses.buttonQty}">
          ${QTY_BOX_HTML.replace(
            QTY_BOX_PLACEHOLDER,
            '<input type="number" min="1" value="1" />'
          )}</div>`
      : '';

  const buttonBoxWQty = `
    ${qtyBox}
    <button 
        class="${className} ${CssClasses.cartButton}${
    qtyType ? ' ' + CssClasses.hasButtonQtyInput : ''
  }"
        onclick="upsell.addToCart('${TOKENS.id}', '${TOKENS.addToCartText}', '${
    TOKENS.removeFromCartText
  }', this, ${isGroupedVariant}, '${productHandle}')" 
        type="button"
        style="${style}">
        ${TOKENS.addToCartText}
    </button>`;

  return renderWithPrice(addOnDetails, buttonBoxWQty, ADDON_TYPE.ButtonQtyBox);
}

export function checkbox(data) {
  const { isGroupedVariant, productHandle, template, addOnDetails } = data;
  const clickHandler = `upsell.toggleAddonSelection('${TOKENS.id}', this, ${isGroupedVariant}, '${productHandle}')`;
  if (template) {
    return template.replace(TOKENS.clickHandler, clickHandler);
  }

  const inputBox = `<input type="checkbox"  onclick="${clickHandler}"/>`;

  return renderWithPrice(addOnDetails, inputBox, ADDON_TYPE.Checkbox);
}

export function qtyBox(data) {
  const { isGroupedVariant, productHandle, addOnDetails } = data;
  const qtyBox = QTY_BOX_HTML.replace(
    QTY_BOX_PLACEHOLDER,
    `<input type="number" min="0" onchange="upsell.captureQty('${TOKENS.id}', this, ${isGroupedVariant}, '${productHandle}')" />`
  );

  return renderWithPrice(addOnDetails, qtyBox, ADDON_TYPE.QtyBox);
}

export function qtyDropdown(data) {
  const { isGroupedVariant, productHandle, options, addOnDetails } = data;

  let optionsHtml = '`<option value=""></option>';
  for (let i = 0; i < options.length; i++) {
    optionsHtml += `<option value="${options[i]}">${options[i]}</option>`;
  }

  const selectBox = `
  <div class="${CssClasses.qtyWrapperDrop}">
    <div class="upsell-qty-label">${TOKENS.qtyLabel}</div>        
    <div class="${CssClasses.qtyBoxInput}">            
        <select class="upsell-qty-dropdown" onchange="upsell.captureQty('${TOKENS.id}', this, ${isGroupedVariant}, '${productHandle}')" >
            ${optionsHtml}
        </select>
    </div>
  </div>
  `;

  return renderWithPrice(addOnDetails, selectBox, ADDON_TYPE.Dropdown);
}

export function variantDropdown(productHandle, variantOptions, label) {
  if (variantOptions && variantOptions.length > 0) {
    let options = '';
    const optionsLabel = label ? `${label}:` : '';
    for (let i = 0; i < variantOptions.length; i++) {
      options += `<option value=${variantOptions[i].id}>${variantOptions[i].title}</option>`;
    }
    return `<div class="${CssClasses.variantDropdown}">
                <label>${optionsLabel}</label>
                <select id="${Element_Ids.varaintSelector}${productHandle}" 
                        onchange="upsell.changeVariant('${productHandle}', 
                                                       '${safeEncodeURIComponent(
                                                         JSON.stringify(
                                                           variantOptions
                                                         )
                                                       )}',
                                                       this.value)">
                        ${options}
                </select>
            </div>`;
  }
  return '';
}

export function sold(style = 'margin: 0 auto;') {
  const isDawn = isDawnTheme();

  const styles = `
    display: flex; 
    justify-content: center;
    ${isDawn ? 'border: .1rem solid rgba(var(--color-foreground),.55);' : ''}
    margin-bottom: 10px;
  `;

  return `
    <div class="price__badges price__badges--listing" 
        style="${style} width: 80px;"
    >
        <span 
            class="price__badge price__badge--sold-out" 
            style="${styles}"
        >
          <span>Sold out</span>
        </span>
    </div>
  `;
}

export function priceHtml(args) {
  const { options } = getAppProps();
  const { hasCompare, compareAtPrice, finalPrice } = args;

  const compareClasses = hasCompare
    ? `${CssClasses.upsellPriceValue} ${CssClasses.hasCompareAtPrice} ${CssClasses.boldDiscount}`
    : `${CssClasses.upsellPriceValue}`;

  const plusSign = options.showPlus ? '+' : '';

  const bStyles = `
    margin-right: ${plusSign !== '' ? '-1px' : '10px'}; 
    font-weight: normal;
  `;

  const finalPriceElement = `
    <span>
      <b style="${bStyles}">
          ${plusSign}
      </b>
      <span id="${Element_Ids.price}" class="${compareClasses} money">
          ${finalPrice}
      </span>
    </span>
  `;

  const prevPriceElement = `
    <span class="${CssClasses.compareAtPrice} money">
        ${compareAtPrice}
    </span>
  `;

  if (!hasCompare) {
    return finalPriceElement;
  }

  let elements = `
    ${prevPriceElement}
    ${breakLine()}
    ${finalPriceElement}
  `;

  if (options.productBoxType === BOX_TYPE.ImageTop) {
    elements = `
     ${finalPriceElement}
     ${breakLine()}
     ${prevPriceElement}
  `;
  }

  return elements;
}

function container(pageTypeClass, containerType, layoutType, productHandle) {
  let layoutClass = CssClasses.defaultLayout;
  if (layoutType === LAYOUT_TYPE.Flex) {
    layoutClass = CssClasses.flexLayout;
  } else if (layoutType === LAYOUT_TYPE.Slider) {
    layoutClass = CssClasses.sliderLayout;
  }
  let idFragement = productHandle
    ? `id=${Element_Ids.addOnItemContainer}${productHandle}`
    : '';
  return `<div ${idFragement} class="${
    CssClasses.container
  } ${containerType} ${theme}">
    <h4 class="upsell-font-color ${CssClasses.header} ${STYLE_TOKENS.header}">${
    TOKENS.headerText
  }</h4>     
      <div class="${CssClasses.addOnContainer} ${layoutClass}${
    layoutType === LAYOUT_TYPE.Default ? ` ${pageTypeClass}` : ''
  }">
        ${TOKENS.addOns}      
      </div>     
  </div>`;
}

function breakLine() {
  const selectedClass = isDawnTheme()
    ? CssClasses.customBreakLineDawn
    : CssClasses.customBreakLine;

  return `
    <div class="${selectedClass}">
    </div>
  `;
}

// Re-export
export {
  Element_Ids,
  TOKENS,
  STYLE_TOKENS,
  CssClasses,
  OPTIONS_TOKENS,
  styles,
  placeholder,
  breakLine
};
