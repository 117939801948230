const UPSELL_ITEM_ADDED_TO_CART = 'upsellAddOnsApp.added';
const UPSELL_ITEM_REMOVED_FROM_CART = 'upsellAddOnsApp.removed';
export function dispatchItemAddedToCartEvent(
  addedViaButton,
  response,
  addedVariantIds
) {
  if (!supportsCustomEvents()) return false;
  const details = addedViaButton
    ? { item: response }
    : { cart: response, variantIds: addedVariantIds };
  const itemAddedEvent = new CustomEvent(UPSELL_ITEM_ADDED_TO_CART, {
    detail: details
  });
  document.dispatchEvent(itemAddedEvent);
}

export function dispatchItemRemovedFromCartEvent(variantId) {
  if (!supportsCustomEvents()) return false;
  const itemRemovedEvent = new CustomEvent(UPSELL_ITEM_REMOVED_FROM_CART, {
    detail: {
      variantId
    }
  });

  document.dispatchEvent(itemRemovedEvent);
}

function supportsCustomEvents() {
  return typeof window.CustomEvent === 'function';
}
