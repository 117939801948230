async function hookInstall(fn, delay = 1000, limit = 3) {
  for (let i = 0; i <= limit; i++) {
    const result = fn();
    if (result) {
      break;
    } else {
      await new Promise(r => setTimeout(r, delay));
    }
  }
}

export default hookInstall;


