import errorHandler from './errorHandler';
import { getSymbolFromCurrency } from './utils/currencies';

function parseSpanMoney(currencyFormat) {
  if (currencyFormat.includes('span')) {
    // Parse from external shopify apps (eg currency converters)
    // eslint-disable-next-line no-undef
    const parser = new DOMParser();

    const doc = parser.parseFromString(currencyFormat, 'text/html');
    return doc.querySelector('span').innerHTML;
  }
  return currencyFormat;
}

function parseMoney(priceHtml) {
  if (!priceHtml || priceHtml === '') {
    return 0;
  }

  const priceTxt = parseSpanMoney(priceHtml);

  try {
    const cur_re = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/;
    const parts = cur_re.exec(priceTxt);
    const number = parseFloat(
      parts[1].replace(/\D/, '') + '.' + (parts[2] ? parts[2] : '00')
    );

    return number;
  } catch (e) {
    return 0;
  }
}

function getCompareAtPrice(compareAtPrice, price) {
  if (!compareAtPrice || compareAtPrice === '') {
    return parseFloat(price);
  }

  return parseFloat(compareAtPrice);
}

export function getPriceDetails(options, price, compareAtPrice) {
  const priceDetails = {};
  const hasCompareAtPrice = parseInt(compareAtPrice) > 0;
  priceDetails.hasCompareAtPrice = hasCompareAtPrice;
  priceDetails.priceNumber = parseFloat(price);
  priceDetails.compareAtPriceNumber = getCompareAtPrice(compareAtPrice, price);
  const shopifyMoneyFormat = getShopifyCurrencyFormat();
  const formatMoneyFunc = Shopify.formatMoney || formatMoney;
  if (formatMoneyFunc && shopifyMoneyFormat && !options.formatMoneyOverride) {
    try {
      priceDetails.price = formatMoneyFunc(price * 100, shopifyMoneyFormat);
      priceDetails.compareAtPrice = hasCompareAtPrice
        ? formatMoneyFunc(compareAtPrice * 100, shopifyMoneyFormat)
        : '';

      // Upd final
      let finalPrice = parseMoney(priceDetails.price);

      // Use default value if smth is wrong
      if (finalPrice === 0) {
        finalPrice = parseFloat(price);
      }

      priceDetails.priceNumber = finalPrice;

      // Upd compareAtPrice
      const cmpAtPrice = parseMoney(priceDetails.compareAtPrice);

      // Use original price if smth is wrong
      if (cmpAtPrice === 0) {
        priceDetails.compareAtPriceNumber = finalPrice;
      } else {
        priceDetails.compareAtPriceNumber = cmpAtPrice;
      }

      return priceDetails;
    } catch (err) {
      errorHandler(err, true);
    }
  }

  const currencySymbol = options.currencyToken || '$';
  priceDetails.price = `${currencySymbol}${
    options.hideDecimal ? price.split('.')[0] : price
  }`;

  priceDetails.compareAtPrice = hasCompareAtPrice
    ? `${currencySymbol}${
        options.hideDecimal ? compareAtPrice.split('.')[0] : compareAtPrice
      }`
    : '';

  return priceDetails;
}

export function isProductPage() {
  return window.location.href.toLowerCase().indexOf('/products/') >= 0;
}

export function isCartPage() {
  return window.location.href.toLowerCase().indexOf('/cart') >= 0;
}

export function getProductJsonUrl() {
  const pathname = window.location.pathname;
  const index = pathname.lastIndexOf('/') + 1;
  return `/products/${pathname.substr(index)}.js`;
}

export function getAddOnProduct(addOnId, type = 'addons') {
  if (!window.upsell[type]) {
    return;
  }

  const intId = parseInt(addOnId);
  for (let i = 0; i < window.upsell[type].length; i++) {
    const addOn = window.upsell[type][i];
    if (!addOn) continue;
    if (addOn.variantOptions) {
      const variant = addOn.variantOptions.find(function(variantOption) {
        return variantOption.id === intId;
      });
      if (variant) {
        return {
          title: addOn.title,
          variant: variant.title,
          price: variant.price
        };
      }
    } else if (intId === addOn.id) {
      return addOn;
    }
  }
}

export function getProductHandleFromRelativeUrl(url) {
  const prodUrl = new URL(
    `https://upsellproductaddons.com/${url
      .toLowerCase()
      .replace('/products/', '')}`
  );
  console.log(prodUrl);
  return prodUrl.pathname.replace('/', '');
}

export function getDefaultCurrencyFormatSymbolName() {
  const defaultCode = 'USD';

  if (window.Shopify && window.Shopify.currency) {
    const currency = window.Shopify.currency.active;
    return currency || defaultCode;
  }

  return defaultCode;
}

export function getDefaultCurrencyFormatSymbol() {
  const codeName = getDefaultCurrencyFormatSymbolName();
  return getSymbolFromCurrency(codeName) || '$';
}

function validCurrencyFormat(format) {
  if (typeof format === 'string' && format.includes('{{')) {
    return true;
  }

  return false;
}

function getShopifyCurrencyFormat() {
  if (window.money_format && validCurrencyFormat(window.money_format)) {
    return window.money_format;
  }

  if (
    window.shopifyCurrencyFormat &&
    validCurrencyFormat(window.shopifyCurrencyFormat)
  ) {
    return window.shopifyCurrencyFormat;
  }

  if (
    window.Currency &&
    window.Currency.money_format &&
    validCurrencyFormat(window.Currency.money_format)
  ) {
    return window.Currency.money_format;
  }

  if (window.theme) {
    if (
      window.theme.moneyFormat &&
      validCurrencyFormat(window.theme.moneyFormat)
    ) {
      return window.theme.moneyFormat;
    }

    if (
      window.theme.shop &&
      window.theme.shop.money_format &&
      validCurrencyFormat(window.theme.shop.money_format)
    ) {
      return window.theme.shop.money_format;
    }

    if (
      window.theme.money_format &&
      validCurrencyFormat(window.theme.money_format)
    ) {
      return window.theme.money_format;
    }

    if (
      window.theme.settings &&
      window.theme.settings.moneyFormat &&
      validCurrencyFormat(window.theme.settings.moneyFormat)
    ) {
      return window.theme.settings.moneyFormat;
    }
  }

  if (
    Shopify &&
    Shopify.money_format &&
    validCurrencyFormat(Shopify.money_format)
  ) {
    return Shopify.money_format;
  }

  const symbol = getDefaultCurrencyFormatSymbol();

  return `${symbol}{{amount}}`;
}

function formatMoney(cents, format) {
  if (typeof cents === 'string') {
    cents = cents.replace('.', '');
  }
  var value = '';
  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  var formatString = format || '${{amount}}';

  function defaultOption(opt, def) {
    return typeof opt === 'undefined' ? def : opt;
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ',');
    decimal = defaultOption(decimal, '.');

    if (isNaN(number) || number === null) {
      return 0;
    }

    number = (number / 100.0).toFixed(precision);

    var parts = number.split('.'),
      dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
      cents = parts[1] ? decimal + parts[1] : '';

    return dollars + cents;
  }

  switch (formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
    case 'amount_with_apostrophe_separator':
      value = formatWithDelimiters(cents, 2, "'", '.');
      break;
  }

  return formatString.replace(placeholderRegex, value);
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getPriceNumberFormatDefault(price, commas = true) {
  const { options } = getAppProps();

  let currencyFormat = getShopifyCurrencyFormat();
  let priceText = price.toFixed(2);

  if (commas) {
    priceText = numberWithCommas(priceText);
  }

  function defaultFormat() {
    return currencyFormat.replace(/\{\{([^)]+)\}\}/, priceText);
  }

  const formatMoneyFunc = Shopify.formatMoney || formatMoney;
  if (formatMoneyFunc && !options.formatMoneyOverride) {
    try {
      return formatMoneyFunc(price * 100, currencyFormat);
    } catch (e) {
      return defaultFormat();
    }
  }

  return defaultFormat();
}

export function setAppProps(args) {
  window.upsell.appProps = args;
}

export function getAppProps() {
  return window.upsell.appProps;
}

export function setMouseLoading(state) {
  if (state) {
    document.body.style.cursor = 'wait';
  } else {
    document.body.style.cursor = 'default';
  }
}

export function disableMouseClick() {
  document.body.style.pointerEvents = 'none';
}

export function getValidClassNames(classes) {
  const blacklistStyles = [
    'loading' // Dawn theme
  ];
  const list = classes.split(' ');
  const arr = list.filter(v => !blacklistStyles.includes(v));
  return arr.join(' ');
}

export function isDebutTheme() {
  if (window.theme && window.theme.Product) {
    return true;
  }

  return false;
}

export function isDawnTheme() {
  if (!window.theme || !window.theme.Product) {
    return true;
  }

  return false;
}

export function getThemeName() {
  if (window.Shopify && window.Shopify.theme && window.Shopify.theme.name) {
    return window.Shopify.theme.name;
  }

  return '';
}

export function setPreparedRecordItems(data) {
  window.upsell.preparedRecordItems = data;
}

export function getPreparedAddonRecordItems() {
  return window.upsell.preparedRecordItems;
}

export function calcSavePrice(compareAtPrice, originalPrice, finalPrice) {
  /*const { options } = getAppProps();

  if (options.showCompareAtPrice) {
    return compareAtPrice - finalPrice;
  } else {
    return originalPrice - finalPrice;
  }*/

  // Take always compare at price number
  return compareAtPrice - finalPrice;
}

export function getOtherAddonDetails({ priceDetails, addOnRecord }) {
  const { options } = getAppProps();

  let finalPrice = priceDetails.priceNumber;

  const discount = parseInt(addOnRecord.discount);
  const hasDiscount = discount > 0;

  if (hasDiscount) {
    finalPrice = finalPrice - (finalPrice / 100) * discount;
  }

  let save = calcSavePrice(
    priceDetails.compareAtPriceNumber,
    priceDetails.priceNumber,
    finalPrice
  );

  const finalPriceText = getPriceNumberFormatDefault(finalPrice);

  let hasCompare = options.showCompareAtPrice && priceDetails.hasCompareAtPrice;
  const compareAtPrice = getPriceNumberFormatDefault(
    priceDetails.compareAtPriceNumber
  );

  if (hasDiscount) {
    hasCompare = true;
  }

  if (save > 0) {
    save = getPriceNumberFormatDefault(save);
  } else {
    save = null;
  }

  return {
    hasCompare,
    hasDiscount,
    compareAtPrice,
    finalPrice: finalPriceText,
    discount,
    save,
    priceNumber: priceDetails.priceNumber,
    finalPriceNumber: finalPrice
  };
}

export function getMainProductTitle() {
  return (
    (window.upsell &&
      window.upsell.product &&
      window.upsell.product.details &&
      window.upsell.product.details.title) ||
    ''
  );
}

export function getAvailableWithTitle() {
  // Return empty string because it can't work on cart page.
  if (isCartPage()) {
    return '';
  }

  const title = getMainProductTitle();
  return `Available with ${title}`;
}

export function getAvailableWith(addOnRecord) {
  return addOnRecord.availableWith ? getAvailableWithTitle() : '';
}

export function isAjaxProductPageHook() {
  const { options } = getAppProps();

  if (
    (Shopify.theme && Shopify.theme.name.toLowerCase() === 'simple') ||
    options.cartType === 'ajax'
  ) {
    return true;
  }

  return false;
}

export function findRecord(list, items) {
  let addOnRecord = null;

  for (const variant of list) {
    const record = items.find(v => v.ids.includes(variant.id));
    if (record) {
      addOnRecord = record;
      break;
    }
  }

  return addOnRecord;
}

export function getMainProductVariant() {
  const product = window.upsell.product.details;

  const availableVariants = product.variants.filter(i => i.available);
  const allVariants = product.variants;
  let selectedVariantId = availableVariants.length
    ? availableVariants[0].id
    : allVariants[0].id;

  const url = new URL(document.URL);
  const isVariantUrl = url.searchParams.get('variant');

  if (isVariantUrl) {
    selectedVariantId = isVariantUrl;
  }

  return selectedVariantId;
}

export function getMainProductQuantity() {
  let quantity = 1;
  // Try get quantity
  if (isDawnTheme()) {
    const parsed = parseInt(window.jUpsell('.quantity__input').val());
    if (!isNaN(parsed)) {
      quantity = parsed;
    }
  }

  return quantity;
}

export function safeEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return (
      '%' +
      c
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()
    );
  });
}

export function getNoteText() {
  const noteField = document.querySelector('form textarea[name="note"]');
  if (noteField) return noteField.value;
}

export function isThemeEditor() {
  return Shopify && Shopify.designMode;
}

export function isRenderCartAddons() {
  const { options } = getAppProps();

  if (options.cart && options.cart !== 'false') {
    return true;
  }

  return false;
}

export function getFormData($form) {
  var unindexed_array = $form.serializeArray();
  var indexed_array = {};

  jUpsell.map(unindexed_array, function(n) {
    indexed_array[n['name']] = n['value'];
  });

  return indexed_array;
}

export function getAttributesForm($form) {
  const obj = getFormData($form);
  if (!obj) {
    return {};
  }

  const result = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && key.includes('attributes[')) {
      const init = key.indexOf('[');
      const fin = key.indexOf(']');
      const name = key.substr(init + 1, fin - init - 1);
      result[name] = obj[key];
    }
  }

  return result;
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function hex2a(hexx) {
  var hex = hexx.toString();//force conversion
  var str = '';
  for (var i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

window.upsellIsDebutTheme = isDebutTheme;
window.upsellIsDawnTheme = isDawnTheme;
window.upsellGetCurrencyFormat = getShopifyCurrencyFormat;
