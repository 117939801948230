import { getAppProps, getProductJsonUrl } from '../utils';
import {
  addOnAddToCartActionHander,
  trackItemsInCart
} from '../cartActionHandler';
import { getAddOns, sortAddOns, wrapAddOns } from '../addOnsUtil';
import getCollectionAddOns from '../collectionAddOns';
import { PAGE } from '../domain';
import getCatalogAddOns from '../catalogAddOns';
import renderer, { removeUpsell, renderPlaceholder } from '../renderer';
import errorHandler from '../errorHandler';
import { setPopUpData } from './productPageInjector/utils';

function prepareAddons({
  addOnsDb,
  id,
  selectedVariantId,
  variantToIgnore,
  groupVairantsTracker,
  options
}) {
  const addOnsTracker = {};
  const addOns = getAddOns(
    function() {
      return wrapAddOns(addOnsDb, id, selectedVariantId);
    },
    addOnsDb,
    addOnsTracker,
    variantToIgnore,
    groupVairantsTracker
  );
  const collectionAddOns = getCollectionAddOns(
    PAGE.Product,
    id,
    addOnsDb,
    addOnsTracker,
    variantToIgnore,
    groupVairantsTracker
  );
  addOns.push.apply(addOns, collectionAddOns);
  if (!options.disableProductPageCatalogAddOns) {
    const allProductAddOns = getCatalogAddOns(
      addOnsDb,
      addOnsTracker,
      variantToIgnore,
      groupVairantsTracker
    );
    addOns.push.apply(addOns, allProductAddOns);
  }
  return addOns;
}

function prepareItems(result, id, selectedVariantId, type) {
  const { options, addOnsDb, popups } = getAppProps();

  let variantToIgnore = {};
  //following line makes sure that we don't show same product as addons of itself
  //if we are treating variants as individual items
  // then use variantId as a tracker otherwise use product handle
  if (options.groupVariants) {
    variantToIgnore[result.product.handle] = true;
  } else {
    variantToIgnore = result.product.variants.reduce(function(
      accumulator,
      variant
    ) {
      accumulator[variant.id] = true;
      return accumulator;
    },
    {});
  }
  const groupVairantsTracker = options.groupVariants ? {} : null;

  const addOns = prepareAddons({
    addOnsDb: type === 'addons' ? addOnsDb : popups,
    id,
    selectedVariantId,
    variantToIgnore,
    groupVairantsTracker,
    options
  });

  if (groupVairantsTracker) {
    for (let groupedVariant in groupVairantsTracker) {
      addOns.push(groupVairantsTracker[groupedVariant]);
    }
  }
  return sortAddOns(addOns);
}

export default function productPage() {
  const { options } = getAppProps();
  try {
    const productDetailsUrl = IS_SHOPIFY_ENV
      ? getProductJsonUrl()
      : '/data/product.json';

    addOnAddToCartActionHander(false, options);
    trackItemsInCart();

    jUpsell
      .getJSON(productDetailsUrl, function(res) {
        try {
          let result = {
            product: res
          };
          if (result && result.product) {
            window.upsell = window.upsell || {};
            window.upsell.product = window.upsell.product || {};
            window.upsell.product.details = result.product;
            const availableVariants = result.product.variants.filter(
              i => i.available
            );
            const allVariants = result.product.variants;
            const id = result.product.id;
            let selectedVariantId = availableVariants.length
              ? availableVariants[0].id
              : allVariants[0].id;

            const url = new URL(document.URL);
            const isVariantUrl = url.searchParams.get('variant');
            if (isVariantUrl) {
              selectedVariantId = isVariantUrl;
            }

            const sortedAddOns = prepareItems(
              result,
              id,
              selectedVariantId,
              'addons'
            );
            const sortedPopupAddOns = prepareItems(
              result,
              id,
              selectedVariantId,
              'popups'
            );
            const visiblePopupAddons = sortedPopupAddOns.length > 0;
            if (visiblePopupAddons) {
              setPopUpData({
                product: res,
                addOns: sortedPopupAddOns
              });
            } else {
              setPopUpData(null);
            }
            if (sortedAddOns.length > 0) {
              renderPlaceholder(PAGE.Product, result.product.handle);
            }
            renderer(sortedAddOns, sortedPopupAddOns, PAGE.Product);
          }
        } catch (err) {
          removeUpsell();
          errorHandler(err);
        }
      })
      .fail(function(response) {
        removeUpsell();
        errorHandler(
          new Error(
            `Errors in fetching product details ${productDetailsUrl}. StatusCode:${response.status}`
          ),
          true
        );
      });
  } catch (err) {
    removeUpsell();
    errorHandler(err);
  }
}
