import {
  getAppProps, getAvailableWith,
  getOtherAddonDetails,
  getPriceNumberFormatDefault
} from '../../utils';
import {
  getAddOnBoxTemplate,
  getAddOnInput,
  getImageUrl,
  getPrices,
  getUrl
} from '../common/utils';
import {
  addOn,
  CssClasses,
  OPTIONS_TOKENS,
  priceHtml,
  TOKENS,
  variantDropdown
} from '../../templates';
import { BOX_TYPE, PAGE } from '../../domain';

function buildAddon(args) {
  const { options } = getAppProps();
  const { addOnDetails, addOnRecord, page, addToCartButton } = args;

  if (addOnDetails) {
    const isGroupedVariant =
      addOnDetails.variantOptions && addOnDetails.variantOptions.length > 0;

    const priceDetails = getPrices(options, addOnDetails, 0);
    const addToCartInput = getAddOnInput(
      options,
      page,
      addToCartButton,
      isGroupedVariant,
      addOnRecord.handle,
      addOnDetails
    );
    const image = getImageUrl(options, addOnDetails, 0);
    const addCartHtml = addToCartInput
      .replace(TOKENS.id, addOnRecord.ids[0])
      .replace(
        new RegExp(TOKENS.addToCartText, 'g'),
        options.addToCartText
          ? decodeURIComponent(options.addToCartText)
          : 'Add to cart'
      )
      .replace(
        TOKENS.removeFromCartText,
        options.removeFromCartText
          ? decodeURIComponent(options.removeFromCartText)
          : 'Remove'
      );

    const availableWith = getAvailableWith(addOnRecord);
    const {
      hasCompare,
      compareAtPrice,
      finalPrice,
      discount
    } = getOtherAddonDetails({
      priceDetails,
      addOnRecord
    });

    const addOnHtml = (
      getAddOnBoxTemplate(options, page) ||
      addOn(
        page === PAGE.Product
          ? options.productBoxType || BOX_TYPE.Default
          : page === PAGE.Cart
          ? options.cartBoxType
          : BOX_TYPE.Default
      )
    )
      .replaceAll(TOKENS.addOnProductHandle, addOnRecord.handle)
      .replace(
        OPTIONS_TOKENS.titleSnippet,
        options.linkTitle
          ? `<a href="${getUrl(addOnDetails, 0)}"   ${
              options.linkInNewPage ? 'target="_blank"' : ''
            }>${addOnDetails.title}</a>`
          : addOnDetails.title
      )
      .replace(TOKENS.img, image)
      .replace(
        OPTIONS_TOKENS.imageClassName,
        image ? CssClasses.image : CssClasses.hidden
      )
      .replace(
        OPTIONS_TOKENS.seeDetails,
        options.showDetailsLink ? CssClasses.inline : CssClasses.hidden
      )
      .replace(TOKENS.seeDetailsText, options.seeDetailsText || '(See details)')
      .replace(TOKENS.addOnSlug, getUrl(addOnDetails, 0))
      .replace(OPTIONS_TOKENS.addToCartInput, addCartHtml)
      .replace(
        TOKENS.priceElement,
        priceHtml({
          hasCompare,
          compareAtPrice,
          finalPrice
        })
      )
      .replace(TOKENS.qtyLabel, options.qtyLabel || 'Qty:')
      .replace(
        OPTIONS_TOKENS.variantDropdown,
        variantDropdown(
          addOnRecord.handle,
          addOnDetails.variantOptions,
          addOnDetails.variantOptionsLabel
        )
      )
      .replace(TOKENS.description, addOnRecord.description || '')
      .replace(TOKENS.availableWith, availableWith)
      .replace(TOKENS.discount, discount);

    return addOnHtml;
  } else {
    return '';
  }
}

export default buildAddon;
