import { getPopUpData } from '../../../pages/productPageInjector/utils';
import {
  calcSavePrice,
  findRecord,
  getAppProps,
  getAvailableWithTitle,
  getPreparedAddonRecordItems,
  getPriceNumberFormatDefault,
  isCartPage,
  isProductPage
} from '../../../utils';
import {
  getActualAddons,
  upsellButtonPrefix,
  upsellCheckboxPrefix,
  upsellInputPrefix
} from './data';
import { isSoldOut, OPTIONS_TOKENS, sold } from '../../../templates';
import { isPopupsLoaded, isShowMainProduct } from './common';
import { TRANSLATE_IDS, translateText } from '../../../utils/translate';
import { variantPopUpDropdown } from './variantSelection';
import { getThemeInstance } from './utils';
import { sortAddOns } from '../../../addOnsUtil';
import { getForm, getSubmitButton } from '../../../render/common/utils';
import { PAGE } from '../../../domain';

const styles = {
  verticalSoldOut: 'margin: 10px auto 0;',
  defaultSoldOut: 'margin: 0;'
};

const parseItem = v => {
  let price = v.price;
  let originalPrice = v.compare_at_price;
  const discount = v.discount;

  if (typeof discount === 'number' && discount > 0) {
    price = price - (price / 100) * discount;
  }

  let save = calcSavePrice(originalPrice, v.price, price);

  const priceText = getPriceNumberFormatDefault(price);
  originalPrice = getPriceNumberFormatDefault(originalPrice);

  let img = '';

  if (v.featured_image && typeof v.featured_image.src === 'string') {
    img = v.featured_image.src;
  }

  if (save > 0) {
    save = getPriceNumberFormatDefault(save);
  } else {
    save = null;
  }

  return {
    ...v,
    price: priceText,
    originalPrice,
    img,
    save,
    priceNumber: v.price,
    finalPriceNumber: price
  };
};

function renderHead(options) {
  const title = options.showPopUpHeaderText
    ? options.popUpHeaderText
    : 'Hang on!';
  const desc = options.showPopUpDescText
    ? options.popUpDescText
    : 'We have a special offer for you:';

  const showDesc = desc !== '';

  return `
    <span id="un-modal-close-btn" onclick="window.upsellClosePopUp(true)">x</span>
    <div id="un-modal-title">${title}</div>
    ${showDesc ? `<div id="un-modal-description">${desc}</div>` : ''}
  `;
}

function isOriginalPriceVisible(data) {
  const { options } = getAppProps();

  let originalPriceVisible = true;
  if (!options.showCompareAtPrice || data.price === data.originalPrice) {
    originalPriceVisible = false;
  }

  // Discount applied
  if (data.priceNumber !== data.finalPriceNumber) {
    originalPriceVisible = true;
  }

  return originalPriceVisible;
}

function renderMainProduct(data) {
  let originalPriceVisible = isOriginalPriceVisible(data);
  return `
      <li class="upsell-product-list-item purchased-product">
        <div class="upsell-product-image-container">
          <span class="checkmark">
            <div class="checkmark_circle"></div>
            <div class="checkmark_stem"></div>
            <div class="checkmark_kick"></div>
          </span>
          <div class="upsell-product-image-wrap">
            <img src="${data.img}" class="upsell-product-image popup-cursor">
          </div>
        </div>
        <div class="upsell-product-info">
          <span class="upsell-product-title">${data.title}</span>
          <div class="upsell-product-prices">
            <span class="upsell-product-price">${data.price}</span>
            <span class="upsell-product-original-price">${
              originalPriceVisible ? data.originalPrice : ''
            }</span>
          </div>
        </div>
      </li>
  `;
}

function renderSave(data, isVertical) {
  const classes =
    'upsell-product-savings ' +
    (isVertical ? 'upsell-product-savings-vertical' : '');

  const saveText = translateText(TRANSLATE_IDS.popUpSaveText);

  let text = '';

  if (isOriginalPriceVisible(data)) {
    text = `${saveText} ${data.save}`;
  }

  return `
    <span class="${classes}">${text}</span>
  `;
}

function renderQty(popUpType, data) {
  let visible = false;

  if (popUpType === 'button') {
    visible = false;
  } else if (popUpType === 'buttonQtyBox') {
    visible = true;
  } else if (popUpType === 'checkbox') {
    visible = false;
  } else if (popUpType === 'checkboxQtyBox') {
    visible = true;
  }

  if (!visible) return '';

  return `
    <input 
        id="${upsellInputPrefix}${data.id}"
        type="number"
        name="quantity"
        value="1"
        required=""
        min="1"
        max="999"
        class="upsell-now-quantity-field"
        pattern="[0-9]*"
    >
  `;
}

function renderButton(popUpType, data, vertical) {
  let input = false;

  if (popUpType === 'buttonQtyBox' || popUpType === 'checkboxQtyBox') {
    input = true;
  }

  const args = `{id: ${data.id},
                  grouped: ${
                    data.variantOptions && data.variantOptions.length > 0
                      ? 'true'
                      : 'false'
                  }, 
                  input: ${input ? 'true' : 'null'}
                }`;
  const onclick = `window.upsellAddPopUpToggleItem(${args})`;

  if (vertical && popUpType === 'checkbox') {
    return `
      <input
        id="${upsellCheckboxPrefix}${data.id}"
        type="checkbox"
        onclick="${onclick}"
        class="uppsell-product-checkbox-left-vertical"
      >
    `;
  }

  if (popUpType === 'checkbox' || popUpType === 'checkboxQtyBox') {
    return `
      <input
        id="${upsellCheckboxPrefix}${data.id}"
        type="checkbox"
        onclick="${onclick}"
        class="upsell-product-add-to-cart-checkbox"
      >
    `;
  }

  const addText = translateText(TRANSLATE_IDS.popUpAddItemText);

  return `
    <button 
        id="${upsellButtonPrefix}${data.id}"
        class="upsell-product-add-to-cart upsell-button" 
        onclick="${onclick}"
    >
        <svg viewBox="0 0 100 100">
            <path d="M25.6 44.4h18.8V25.6c0-7.5 11.2-7.5 11.2 0v18.8h18.8c7.5 0 7.5 11.2 0 11.2H55.6v18.8c0 7.5-11.2 7.5-11.2 0V55.6H25.6c-7.5 0-7.5-11.2 0-11.2z" fill="currentColor" fill-rule="nonzero"></path>
        </svg>
        <span>${addText}</span>
    </button>
  `;
}

function renderImageContainer(args) {
  const { showLeftCheckbox, popUpType, data, isVertical, showSold } = args;

  let showBtn = true;
  if (showSold) {
    showBtn = false;
  }
  if (!showLeftCheckbox) {
    showBtn = false;
  }

  return `
    <div class="upsell-product-image-container">
        ${showBtn ? renderButton(popUpType, data, isVertical) : ''}
        <div class="upsell-product-image-wrap">
            <span class="checkmark" style="display:none;">
              <div class="checkmark_circle"></div>
              <div class="checkmark_stem"></div>
              <div class="checkmark_kick"></div>
            </span>
            <img src="${data.img}" class="upsell-product-image">
        </div>
    </div>
  `;
}

function renderCartFields(args) {
  const { showLeftCheckbox, popUpType, data, isVertical, showSold } = args;

  let showInputBlock = true;

  if (showSold) {
    showInputBlock = false;
  }

  if (showLeftCheckbox) {
    showInputBlock = false;
  }

  const classWrap = isVertical
    ? 'upsell-product-cart-fields-vertical'
    : 'upsell-product-cart-fields';

  return `
    <div class="${classWrap}">
          ${!showSold ? renderQty(popUpType, data) : ''}
          ${showInputBlock ? renderButton(popUpType, data, isVertical) : ''}
    </div>
  `;
}

function renderTitle(data, options, isVertical) {
  const classesTitle =
    'upsell-product-title upsell-product-title-max ' +
    (isVertical ? '' : 'upsell-product-title-horizontal');

  const style = `
    text-decoration: inherit;
    color: inherit;
  `;

  let title = data.title;
  const titleElement = options.popUpLinkTitle
    ? `<a style="${style}" href="${data.url}"   ${
        options.linkInNewPage ? 'target="_blank"' : ''
      }>${title}</a>`
    : title;

  const seeDetails = options.showPopUpDetailsLink;
  const detailsText = options.seeDetailsText || '(See details)';

  const seeDetailsElement = seeDetails
    ? `
    <a style="${style}" href="${data.url}" target="_blank">
        ${detailsText}
    </a>
  `
    : '';

  return `
    <div class="${classesTitle}">${titleElement}</div>
    ${seeDetailsElement}
  `;
}

function renderDescBlock(data, _, isVertical) {
  const classesDesc = isVertical
    ? 'upsell-product-desc-vertical'
    : 'upsell-product-desc-horizontal';

  const description = data.description;
  const availableWith = data.availableWith;
  const handle = data.handle;

  let html = `
        <div class="${classesDesc}">
            ${OPTIONS_TOKENS.variantDropdown}
            <div>${description ? description : ''}</div>
            <div class="upsell-product-available-with">
                ${OPTIONS_TOKENS.availableWith}
            </div>
        </div>
  `;

  html = html
    .replace(
      OPTIONS_TOKENS.variantDropdown,
      variantPopUpDropdown(
        handle,
        data.variantOptions,
        data.variantOptionsLabel,
        data
      )
    )
    .replace(
      OPTIONS_TOKENS.availableWith,
      availableWith ? getAvailableWithTitle() : ''
    );

  return html;
}

function renderRow(data, options) {
  const isVertical = options.popUpBoxType === 'ImgTop';
  const classes =
    'upsell-product-list-item ' +
    (isVertical
      ? 'upsell-product-list-item-vertical'
      : 'upsell-product-list-item-horizontal');

  const classesOriginalPrice =
    'upsell-product-original-price ' +
    (isVertical ? 'upsell-product-original-price-vertical' : '');

  const classesProductPrices =
    'upsell-product-prices ' +
    (isVertical ? 'upsell-product-prices-vertical' : '');

  const popUpType = options.popUpAddOnType;
  const showLeftCheckbox = isVertical && popUpType === 'checkbox';
  let originalPriceVisible = isOriginalPriceVisible(data);

  let showSold = false;
  if (isSoldOut(data)) {
    showSold = true;
  }

  let soldStyle = styles.defaultSoldOut;
  if (isVertical) {
    soldStyle = styles.verticalSoldOut;
  }

  const descBlock = renderDescBlock(data, options, isVertical);
  const titleElement = renderTitle(data, options, isVertical);

  return `
    <li class="${classes}" id="upsell-product-${data.id}">
        ${renderImageContainer({
          showLeftCheckbox,
          popUpType,
          data,
          isVertical,
          showSold
        })}
        <div class="upsell-product-info">
          ${titleElement}
          <div class="${classesProductPrices}">
            <span class="upsell-product-price">${data.price}</span>
            <span class="${classesOriginalPrice}">
                ${originalPriceVisible ? data.originalPrice : ''}
            </span>
            ${renderSave(data, isVertical)}
          </div>
          ${showSold ? sold(soldStyle) : ''}
          ${!isVertical ? descBlock : ''}
        </div>
        ${renderCartFields({
          showLeftCheckbox,
          popUpType,
          data,
          isVertical,
          showSold
        })}
        ${isVertical ? descBlock : ''}
      </li>
  `;
}

function renderBottom(options) {
  const addOnType = options.popUpAddOnType;
  let isCheckout = false;

  let checkoutStyle = '';

  let btn = null;
  if (isProductPage()) {
    btn = document.querySelector('.shopify-payment-button__button--unbranded');
  }

  if (isCartPage()) {
    const page = PAGE.Cart;
    const cartForm = getForm(page, options);
    if (cartForm) {
      btn = getSubmitButton(options, cartForm, page);
    }
  }

  if (btn) {
    const styles = window.getComputedStyle(btn);

    const bgColor = styles.backgroundColor;
    const color = styles.color;
    const borderColor = styles.borderColor;

    checkoutStyle = `
      background-color: ${bgColor};
      color: ${color};
      border-color: ${borderColor};
      width: 150px;
    `;
  }

  let showNoImGood = false;
  let cartText = translateText(TRANSLATE_IDS.popUpContinueShoppingText);

  if (isCartPage()) {
    cartText = translateText(TRANSLATE_IDS.popUpContinueText);
  }

  if (getThemeInstance().isUseContinueText()) {
    isCheckout = true;
    cartText = translateText(TRANSLATE_IDS.popUpCheckoutText);
  }

  if (addOnType === 'checkbox' || addOnType === 'checkboxQtyBox') {
    cartText = translateText(TRANSLATE_IDS.popUpAddToCartText);
    showNoImGood = true;
  }

  const textNoIamGood = translateText(TRANSLATE_IDS.popUpNoIamGoodText);

  return `
    <div class="upsell-footer-buttons">
      ${
        showNoImGood
          ? `<a 
        href="#"
        onclick='window.upsellAddPopUpMainProduct()' 
        class="upsell-now-no-thanks" 
        id="upsell-popup-close-link"
      >
        ${textNoIamGood}
      </a>`
          : ''
      }
      <button 
        ${isCheckout ? `style="${checkoutStyle}"` : ''}
        class="upsell-button ${isCheckout ? '' : 'upsell-now-continue'}" 
        id="upsell-popup-continue-button"
        onclick="window.upsellAddPopUpSelectProducts()"
      >
        ${cartText}
      </button>
    </div>
  `;
}

function renderRows(rows, options) {
  const isVertical = options.popUpBoxType === 'ImgTop';
  const showMax2Variants = isVertical && rows.length <= 2;
  const rowsStr = rows.join('');

  if (!showMax2Variants) {
    return rowsStr;
  }

  return `
    <div class='upsell-product-container-wrapper-vertical'>
        ${rowsStr}
    </div>
  `;
}

function renderLoader() {
  return `
    <div class="upsell-popup-loader">
    </div>
  `;
}

function addItem({ v, rows }) {
  let compareAtPrice = v.compareAtPrice;

  if (!compareAtPrice || compareAtPrice === '') {
    compareAtPrice = v.price;
  }

  let addOnRecord = {};
  const recordItems = getPreparedAddonRecordItems();
  if (recordItems && recordItems.popupItems) {
    addOnRecord = findRecord([{ id: v.id }], recordItems.popupItems) || {};
  }

  const item = parseItem({
    ...v,
    ...addOnRecord,
    price: parseFloat(v.price),
    compare_at_price: parseFloat(compareAtPrice),
    featured_image: { src: v.image }
  });

  let visible = false;

  if (isProductPage() && item.productPageVisible) {
    visible = true;
  }

  if (isCartPage() && item.cartPageVisible) {
    visible = true;
  }

  if (visible) {
    rows.push(item);
  }
}

function initRows(items, options) {
  return items.map(v => renderRow(v, options));
}

export function render() {
  const { options } = getAppProps();
  const { product: data } = getPopUpData();
  const actualAddons = getActualAddons();
  const addOns = sortAddOns(actualAddons);

  let mainProduct = null;
  if (data) {
    let compareAtPrice = data.compare_at_price;
    if (!compareAtPrice || compareAtPrice === '') {
      compareAtPrice = data.price;
    }

    const item = parseItem({
      ...data,
      price: parseFloat(data.price) / 100,
      compare_at_price: parseFloat(compareAtPrice) / 100,
      discount: 0,
      productPageVisible: true,
      cartPageVisible: true
    });

    mainProduct = {
      ...item,
      img: data.featured_image
    };
  }

  const rows = [];

  addOns.forEach(v => {
    const variantOptions = v.variantOptions;
    if (variantOptions) {
      addItem({ v: { ...variantOptions[0], ...v }, rows });
    } else {
      addItem({ v, rows });
    }
  });

  const rowsList = initRows(rows, options);
  const showMainProduct = isShowMainProduct();
  const showLoader = !isPopupsLoaded();

  let html = `
    <div id="un-modal-background" class="un-manual-modal-container">
      <div id="un-modal-content">
        ${renderHead(options)}
        <ul id="upsell-product-table">
          ${showMainProduct ? renderMainProduct(mainProduct) : ''}
          ${showLoader ? renderLoader() : renderRows(rowsList, options)}
        </ul>
        ${renderBottom(options)}
      </div>
    </div>
  `;

  return html;
}
