import { Element_Ids } from './templates';
import errorHandler from './errorHandler';
import {
  getAddOnQuantity,
  setCurrentCart,
  cartDetailsUrl
} from './currentCart';
import analytics from './analytics';
import { adjustProductPrice } from './priceUpdater';
import { ADDON_TYPE } from './domain';
import {
  dispatchItemAddedToCartEvent,
  dispatchItemRemovedFromCartEvent
} from './eventsApi';
import { isAjaxProductPageHook } from './utils';
import showError from './utils/error';
import { xhrIntercept } from './utils/xhr';

const addOnIds = {};

export function removeAddOnIds(ids) {
  for (let i = 0; i < ids.length; i++) {
    delete addOnIds[`${ids[i]}`];
  }
}

export function addAddOnId(id, qty) {
  addOnIds[id] = parseInt(qty);
}

export function selectedAddOns() {
  return addOnIds;
}

function queryAddToCart({ isRemove, qty, variantId }) {
  return jUpsell.ajax({
    url: isRemove ? '/cart/change.js' : '/cart/add.js',
    dataType: 'json',
    data: {
      quantity: isRemove ? 0 : qty,
      id: variantId
    },
    type: 'POST',
    xhrFields: {
      withCredentials: true
    }
  });
}

export function addOnAddToCartActionHander(refreshPage, options) {
  window.upsell = window.upsell || {};

  window.upsell.addToCart = function(
    id,
    addText,
    removeText,
    element,
    isGroupedVariant,
    productHandle
  ) {
    const variantId = getVariantId(id, isGroupedVariant, productHandle);
    const button = jUpsell(element);
    const buttonText = button.text();
    button.data('upsell-addText', addText);
    const isRemove = button.data('upsell-remove');
    button.attr('disabled', true);
    const addOnContainer = document.querySelector(
      `#${Element_Ids.addOnItemContainer}${productHandle}`
    );
    const qtyElement = addOnContainer.querySelector('input[type="number"]');
    let qty = 1;
    if (qtyElement && parseInt(qtyElement.value) >= 0) {
      qty = parseInt(qtyElement.value);
    }

    queryAddToCart({
      isRemove,
      qty,
      variantId
    })
      .done(function(response) {
        button.text(isRemove ? addText : removeText);
        button.data('upsell-remove', !isRemove);
        const addonToTrack = {};
        addonToTrack[variantId] = 1;
        if (isRemove) {
          dispatchItemRemovedFromCartEvent(variantId);
        } else {
          dispatchItemAddedToCartEvent(true, response);
        }
        analytics(addonToTrack, ADDON_TYPE.Button, isRemove);
        if (refreshPage) {
          window.location.reload();
        }
      })
      .fail(function(xhr) {
        button.text(buttonText);
        showError(xhr, options, 'Errors Occured!');
      })
      .always(function() {
        button.removeAttr('disabled');
        button.blur();
      });
  };
}

function isSkip() {
  // Skip if we use pop up
  const popUpData = window.upsellGetPopUpData();
  if (popUpData !== null) {
    return true;
  }

  return false;
}

export function addOnCheckboxActionHandler(cartForm, button, options) {
  window.upsell = window.upsell || {};

  window.upsell.toggleAddonSelection = function(
    id,
    element,
    isGroupedVariant,
    productHandle
  ) {
    const variantId = getVariantId(id, isGroupedVariant, productHandle);
    const checked = element.checked;
    if (checked) {
      addOnIds[variantId] = 1;
    } else {
      delete addOnIds[variantId];
    }
    adjustProductPrice(addOnIds, options);
  };
  window.upsell.captureQty = function(
    id,
    element,
    isGroupedVariant,
    productHandle
  ) {
    const qty = parseInt(element.value);
    const variantId = getVariantId(id, isGroupedVariant, productHandle);

    if (qty > 0) {
      addOnIds[variantId] = qty;
    } else {
      delete addOnIds[variantId];
    }
  };

  if (isAjaxProductPageHook()) {
    xhrIntercept('/cart/add.js', function() {
      if (isSkip()) {
        return;
      }

      addSelectedAddOns(
        addOnIds,
        false,
        function() {},
        function() {
          button.blur();
        },
        options
      );
    });
  } else if (options.cartType === 'form') {
    cartForm.addEventListener('submit', function(evt) {
      if (isSkip()) {
        return;
      }

      try {
        if (jUpsell.isEmptyObject(addOnIds)) {
          return;
        }
        evt.preventDefault();
        addSelectedAddOns(
          addOnIds,
          false,
          function() {
            evt.currentTarget.submit();
          },
          function() {
            button.blur();
          },
          options
        );
      } catch (err) {
        evt.currentTarget.submit(); //carry on adding
        errorHandler(err); //May not log but still attempt
      }
    });
  } else {
    button.addEventListener('click', function() {
      if (isSkip()) {
        return;
      }

      // Add items to cart && update analytics.
      addSelectedAddOns(
        addOnIds,
        false,
        function() {},
        function() {
          button.blur();
        },
        options
      );
    });
  }

  if (options.teeInBlueApp) {
    document.addEventListener('teeinblue-event-after-cart-added', function() {
      addSelectedAddOns(
        addOnIds,
        false,
        function() {},
        function() {
          button.blur();
        }
      );
    });
  }
}

export function trackItemsInCart() {
  xhrIntercept('/cart.js', function(response) {
    try {
      if (response.responseText) {
        const cart = JSON.parse(response.responseText);
        setCurrentCart(cart);
      }
    } catch (err) {
      console.log(err);
      setCurrentCart();
    }
  });
  jUpsell.getJSON(cartDetailsUrl);
}

function addSelectedAddOns(addOnIds, async, onSuccess, onError, options) {
  if (jUpsell.isEmptyObject(addOnIds)) {
    if (onSuccess) onSuccess();
    return;
  }
  let addOnsData = '';
  const variantIds = [];
  for (let addOnId in addOnIds) {
    addOnsData += `updates[${addOnId}]=${getQty(addOnId, addOnIds[addOnId])}&`;
    variantIds.push(parseInt(addOnId));
  }
  jUpsell
    .ajax({
      url: '/cart',
      async: async,
      data: encodeURI(addOnsData),
      type: 'POST',
      xhrFields: {
        withCredentials: true
      }
    })
    .done(function(response) {
      let addOnType = options.addOnType;
      dispatchItemAddedToCartEvent(false, response, variantIds);
      analytics(
        addOnIds,
        options.quantitySelect ? ADDON_TYPE.QtyBox : addOnType,
        false
      );
      if (onSuccess) onSuccess();
    })
    .fail(function(xhr) {
      showError(xhr, options);
      if (onError) onError();
    });
}

function getQty(id, qty) {
  return getAddOnQuantity(id) + qty;
}

function getVariantId(defaultVariantId, isGroupedVariant, productHandle) {
  if (isGroupedVariant) {
    return document.querySelector(
      `#${Element_Ids.varaintSelector}${productHandle}`
    ).value;
  }
  return defaultVariantId;
}

window.upsellGetSelectedAddons = selectedAddOns;
