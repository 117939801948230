import { CssClasses } from '../../../templates';
import {
  getAppProps,
  getDefaultCurrencyFormatSymbolName,
  getPriceNumberFormatDefault
} from '../../../utils';

export function setSubtotalDiscountVisible(state) {
  const jUpsell = window.jUpsell;

  const hiddenClass = CssClasses.hidden;

  if (state) {
    jUpsell(`.${CssClasses.customSubtotalOriginalPrice}`).removeClass(
      hiddenClass
    );
    jUpsell(`.${CssClasses.customSubtotalPrice}`).removeClass(hiddenClass);
    jUpsell(`.${CssClasses.customRegularPrice}`).addClass(hiddenClass);
  } else {
    jUpsell(`.${CssClasses.customSubtotalOriginalPrice}`).addClass(hiddenClass);
    jUpsell(`.${CssClasses.customSubtotalPrice}`).addClass(hiddenClass);
    jUpsell(`.${CssClasses.customRegularPrice}`).removeClass(hiddenClass);
  }
}

export function updateSubtotalNumbers(original, final) {
  const { options } = getAppProps();
  const skipCurrencySymbolSubtotal = options.skipCurrencySymbolSubtotal;

  const currencySymbol = skipCurrencySymbolSubtotal
    ? ''
    : getDefaultCurrencyFormatSymbolName();

  const subtotalOriginalFormat = getPriceNumberFormatDefault(original);
  const subtotalOriginalText = `${subtotalOriginalFormat} ${currencySymbol}`;

  const subtotalFormat = getPriceNumberFormatDefault(final);
  const subtotalText = `${subtotalFormat} ${currencySymbol}`;

  jUpsell(`.${CssClasses.customSubtotalOriginalPrice}`).append(
    subtotalOriginalText
  );
  jUpsell(`.${CssClasses.customSubtotalPrice}`).append(subtotalText);
  jUpsell(`.${CssClasses.customRegularPrice}`).append(subtotalOriginalText);
}

export function createCustomSubtotal(element) {
  if (!element) return;

  jUpsell(element).replaceWith(function() {
    return `
             <div class=${CssClasses.subtotalPriceBlock}>
                <s class="${CssClasses.customSubtotalOriginalPrice} ${CssClasses.hidden}" data-cart-item-original-price> 
                </s>
                <div class="${CssClasses.customSubtotalPrice} order-discount ${CssClasses.hidden}"></div>
                <div class="${CssClasses.customRegularPrice}"></div>
             </div>`;
  });
}
