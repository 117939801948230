import createNativeToast from '../nativeToast';

export default function showInfo(msg, options) {
  createNativeToast(function() {
    nativeToast({
      message: msg,
      type: 'success',
      position: options.errPosition || 'south-east',
      timeout: 5000,
      rounded: options.errToast,
      edge: !options.errToast
    });
  });
}
