import { Element_Ids } from '../../../templates';

const upsellInputPrefix = 'upsell-addon-input-popup-custom-';
const upsellButtonPrefix = 'upsell-addon-button-popup-custom-';
const upsellCheckboxPrefix = 'upsell-addon-checkbox-popup-custom-';
const popUpId = 'pop-up-window';
const modalId = 'un-modal-content';

export function getGroupedVariantId(args) {
  if (args.grouped) {
    return document.querySelector(
      `#${Element_Ids.popUpVariantSelector}${args.id}`
    ).value;
  }
  return args.id;
}

export function getActualAddons() {
  return window.upsell.popups || [];
}

export {
  popUpId,
  modalId,
  upsellInputPrefix,
  upsellButtonPrefix,
  upsellCheckboxPrefix
};
