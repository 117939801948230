import Wrapper from '../../template/components/popUp/wrapper';
import { getPopUpData } from './utils';
import { initPopUp } from '../../template/components/popUp';
import { callEmptyNextStep, callNextStep } from './common';
import { getAppProps } from '../../utils';
import hookInstall from '../../utils/hookInstall';

const attributeSetting = 'upsell-hooked-buy-it-now';

function getButton() {
  const { options } = getAppProps();

  let btn = null;
  if (options.buyItNowButtonSelector) {
    btn = document.querySelector(options.buyItNowButtonSelector);
  }

  if (!btn) {
    return (
      document.querySelector('[data-testid="Checkout-button"]') ||
      document.querySelector('.shopify-payment-button__button')
    );
  }
}

function addHookBuyItNow() {
  if (document.querySelector(`[${attributeSetting}="true"]`)) {
    return false;
  }

  const btnReplace = getButton();
  if (!btnReplace) {
    return false;
  }

  // Don't hook if it's disabled
  if (btnReplace.disabled) {
    return false;
  }

  // Replace button by ours (prevent default clicks).
  jUpsell(btnReplace).replaceWith(jUpsell(btnReplace).clone());

  // Hook buy it now
  const btn = getButton();
  if (!btn) {
    return false;
  }

  btn.setAttribute(attributeSetting, 'true');

  const wrap = new Wrapper({
    useContinueText: true
  });
  wrap.onAddToCart = () => {
    callNextStep();
  };
  wrap.onEmpty = () => {
    callEmptyNextStep();
  };
  wrap.onClose = () => {
    callEmptyNextStep();
  };

  btn.addEventListener('click', e => {
    e.preventDefault();

    const variants = getPopUpData();

    if (variants !== null) {
      initPopUp(wrap);
    } else {
      // Add main product
      // Call discount
      callEmptyNextStep();
    }
  });

  return true;
}

function onDomChange() {
  const el = document.querySelector('[data-shopify="payment-button"]');
  if (el) {
    const MutationObserver =
      window.MutationObserver || window.WebKitMutationObserver;

    let enabledCalls = true;

    var observer = new MutationObserver(function(mutations) {
      if (!enabledCalls) {
        return;
      }

      if (mutations.length > 0) {
        enabledCalls = false;
        addHookBuyItNow();
        enabledCalls = true;
      }
    });

    observer.observe(el, {
      subtree: true,
      attributes: true
    });
  }
}

async function loadHook() {
  await hookInstall(addHookBuyItNow, undefined, 10);

  onDomChange();
}

export function hookBuyItNow() {
  if (document.readyState === 'complete') {
    loadHook();
  } else {
    // Load only after loading all
    if (window.attachEvent) {
      window.attachEvent('onload', loadHook);
    } else {
      window.addEventListener('load', loadHook, false);
    }
  }

  return true;
}
