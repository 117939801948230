import {
  createCustomSubtotal,
  setSubtotalDiscountVisible,
  updateSubtotalNumbers
} from '../../ui/subtotal';
import { getAppProps, getPriceNumberFormatDefault } from '../../../../utils';
import parseMoney from 'parse-money';
import { getForm } from '../../../../render/common/utils';
import { PAGE } from '../../../../domain';
import { CssClasses } from '../../../../templates';

export function injectSubtotal({
  subtotalOriginal,
  subtotal,
  subtotalElement
}) {
  const hasDiscount = subtotalOriginal !== subtotal;

  createCustomSubtotal(subtotalElement);
  updateSubtotalNumbers(subtotalOriginal, subtotal);
  setSubtotalDiscountVisible(hasDiscount);
}

function isSubtotalElement(element) {
  if (!element) {
    return false;
  }
  const el = jUpsell(element);
  const classesList =
    (el && el.attr('class') && el.attr('class').split(' ')) || [];
  let foundedClasses = classesList.filter(v => v.includes('subtotal'));
  if (foundedClasses.length === 0) {
    foundedClasses = foundedClasses.filter(v => v.includes('total'));
  }
  return foundedClasses.length !== 0;
}

function getCartRows(form) {
  const { options } = getAppProps();
  if (options.checkoutPageFormSelector) {
    return form.querySelectorAll(options.checkoutPageFormSelector);
  }

  let elements = form.querySelectorAll("[class~='cart__row']");
  if (elements.length === 0) {
    elements = form.querySelectorAll("[class~='item']");
  }
  if (elements.length === 0) {
    elements = form.querySelectorAll("[class~='cart-item']");
  }

  return elements;
}

function getSubtotalElement(form) {
  const { options } = getAppProps();

  // If defined already
  const definedSubtotal = document.querySelector(
    `.${CssClasses.subtotalPriceBlock}`
  );
  if (definedSubtotal) {
    return definedSubtotal;
  }

  if (options.checkoutPageFormSubtotalSelector) {
    let result = null;
    const list = document.querySelectorAll(
      options.checkoutPageFormSubtotalSelector
    );
    list.forEach(v => {
      const info = parseMoney(v.innerText);
      if (info && info.currency) {
        result = v;
      }
    });
    return result;
  } else {
    return (
      form.querySelector('.cart__subtotal') ||
      form.querySelector('.subtotal') ||
      form.querySelector('.cart-subtotal__price') || // debut
      document.querySelector('.totals__subtotal-value') // dawn
    );
  }
}

export function injectCart() {
  const { options } = getAppProps();

  const page = PAGE.Cart;
  const cartForm = getForm(page, options);
  if (!cartForm) {
    return;
  }

  const rows = getCartRows(cartForm);
  if (rows.length === 0) {
    return;
  }

  const result = {
    subtotalElement: null,
    items: [],
    aTags: []
  };

  const subtotalElement = getSubtotalElement(cartForm);
  if (subtotalElement) {
    result.subtotalElement = subtotalElement;
  }

  rows.forEach(v => {
    const allElements = [...v.getElementsByTagName('*')];
    const items = [];
    let aTag = null;
    allElements.forEach(v => {
      if (v.childNodes.length === 1 && v.childNodes[0].nodeValue) {
        if (jUpsell(v).is(':visible') && typeof v.innerText === 'string') {
          const info = parseMoney(v.innerText);
          if (info) {
            let valid = false;

            // if it has currency
            if (info.currency) {
              valid = true;
            }

            // if it's just a number
            if (!valid && parseFloat(v.innerText)) {
              valid = true;
            }

            if (valid) {
              items.push(v);
            }
          }
        }
      }

      // If it's a link
      if (!aTag && v.tagName === 'A') {
        const aElement = v;
        const ahref = aElement.href;
        if (ahref.includes('/cart/change?') && ahref.includes('quantity=0')) {
          aTag = aElement;
        }
      }
    });

    const len = items.length;
    if (len > 0) {
      let price = null;
      let totalPrice = null;

      if (len === 1) {
        totalPrice = items[0];
      } else {
        price = items[0];
        totalPrice = items[1];
      }

      result.items.push({
        price,
        totalPrice
      });
    }

    if (aTag) {
      result.aTags.push(aTag);
    }
  });

  // Subtotal check again (if is not set)
  if (!result.subtotalElement) {
    const lastItem = result.items[result.items.length - 1];
    if (
      lastItem &&
      (isSubtotalElement(lastItem.price) ||
        isSubtotalElement(lastItem.totalPrice))
    ) {
      result.subtotalElement = isSubtotalElement(lastItem.price)
        ? lastItem.price
        : lastItem.totalPrice;
      // remove subtotal from end
      result.items = result.items.slice(0, -1);
    }
  }

  return result;
}

export function renderDiscountPrice(element, original, final) {
  element.innerHTML = `
        <div class="${CssClasses.cartDiscountPriceWrap}">
          <div>
            <s>
                ${getPriceNumberFormatDefault(original)}
            </s>
          </div>
          <div>
            <b style="color: black">
                ${getPriceNumberFormatDefault(final)}
            </b>
          </div>
          </div>
          `;
}

export function injectLineSubtotal(args) {
  const info = args.injectInfo;
  if (!info) return;

  const iter = args.iter;
  const item = info.items[iter - 1];
  if (item) {
    const price = item.price;
    if (price) {
      renderDiscountPrice(price, args.costOld, args.cost);
    }
    const totalPrice = item.totalPrice;
    if (totalPrice) {
      renderDiscountPrice(totalPrice, args.totalPriceOld, args.totalPrice);
    }
  }
}

function applyLoadingAnim(element, state) {
  if (state) {
    jUpsell(element).addClass(CssClasses.cartSpinner);
  } else {
    jUpsell(element).removeClass(CssClasses.cartSpinner);
  }
}

export function injectLoadingAnim(info, state) {
  info.items.forEach(v => {
    if (v.price) {
      applyLoadingAnim(v.price, state);
    }
    if (v.totalPrice) {
      applyLoadingAnim(v.totalPrice, state);
    }
  });
}
