const TOKENS = {
  headerText: '{{headerText}}',
  addOns: '{{addOns}}',
  img: '{{img}}',
  title: '{{title}}',
  addOnSlug: '{{addOnSlug}}',
  id: '{{id}}',
  addToCartText: '{{addToCartText}}',
  removeFromCartText: '{{addedToCart}}',
  seeDetailsText: '{{seeDetailsText}}',
  qtyLabel: '{{qtyLabel}}',
  addOnProductHandle: '{{addOnProductHandle}}',
  description: '{{description}}',
  clickHandler: '{{clickHandler}}',
  discount: '{{discount}}',
  priceElement: '{{priceElement}}',
  availableWith: '{{availableWith}}'
};

export default TOKENS;
