export default function(err, ignore) {
  if (IS_LOGGING) {
    if (!ignore) {
      Sentry.setExtra('shop_url', window.location.href);
      Sentry.captureException(err);
    }
  }
  if (console) {
    console.log(err);
  }
}
