const OPTIONS_TOKENS = {
  plusSign: '{{plus}}',
  seeDetails: '{{seeDetails}}',
  titleSnippet: '{{titleSnippet}}',
  addToCartInput: '{{addToCartInput}}',
  imageClassName: '{{imageClassName}}',
  variantDropdown: '{{variantDropdown}}',
  hasCompareAtPriceClassName: '{{hasCompareAtPriceClassName}}',
  availableWith: '{{availableWith}}'
};

export default OPTIONS_TOKENS;
