export function getAddOns(
  getParent,
  addOnsDb,
  addOnsTracker,
  addOnsToIgnore,
  groupVairantsTracker
) {
  const currentParent = getParent(addOnsDb); //addOnsDb[productId];
  const addOns = [];
  for (let addOn in currentParent) {
    if (groupVairantsTracker && isVariant(addOn)) {
      trackVariant(currentParent, groupVairantsTracker, addOn, addOnsToIgnore);
    } else {
      const currentAddOnRecord = createAddOnRecord(currentParent, addOn);
      const addOnToIgnore = groupVairantsTracker
        ? addOnsToIgnore[currentAddOnRecord.handle]
        : addOnsToIgnore[currentAddOnRecord.ids[0]];
      if (!addOnsTracker[addOn] && !addOnToIgnore) {
        addOnsTracker[addOn] = true;
        addOns.push(createAddOnRecord(currentParent, addOn));
      }
    }
  }
  return addOns;
}

export function isVariant(addOn) {
  return addOn.indexOf('|') >= 0;
}

export function trackVariant(
  product,
  groupVairantsTracker,
  addOn,
  addOnsToIgnore
) {
  const slugAndId = addOn.split('|');
  if (addOnsToIgnore[slugAndId[0]]) {
    return;
  }
  const variantRecord = groupVairantsTracker[slugAndId[0]];
  const variantId = parseInt(slugAndId[1]);
  if (variantRecord && variantRecord.ids.indexOf(variantId) < 0) {
    variantRecord.ids.push(variantId);
    // if we don't have order info, check if other variants have order specified
    if (!variantRecord.order) {
      const otherVariantRecord = createAddOnRecord(product, addOn);
      variantRecord.order = otherVariantRecord.order;
    }
  } else {
    groupVairantsTracker[slugAndId[0]] = createAddOnRecord(product, addOn);
  }
}

export function createAddOnRecord(product, addOn) {
  const slugAndId = addOn.split('|');
  const addOnRecordFragments = product[addOn].toString().split('|');
  const addOnRecord = {
    handle: slugAndId[0],
    ids: [parseInt(addOnRecordFragments[0])],
    productPageVisible: true
  };
  const len = addOnRecordFragments.length;
  if (len >= 2) {
    addOnRecord.order = parseInt(addOnRecordFragments[1]);
  }
  if (len >= 3) {
    addOnRecord.description = decodeURIComponent(addOnRecordFragments[2]); // 2 is description
  }
  if (len >= 4) {
    addOnRecord.discount = Number(addOnRecordFragments[3]); // 3 is discount
  }
  if (len >= 5) {
    addOnRecord.specified = addOnRecordFragments[4] === 'true'; // 4 is specified
  }
  if (len >= 6) {
    addOnRecord.productPageVisible = addOnRecordFragments[5] === 'true'; // 5 is productPageVisible
  }
  if (len >= 7) {
    addOnRecord.cartPageVisible = addOnRecordFragments[6] === 'true'; // 6 is cartPageVisible
  }
  if (len >= 8) {
    addOnRecord.availableWith = addOnRecordFragments[7] === 'true'; // 7 is availableWith
  }
  return addOnRecord;
}

export function sortAddOns(addOns) {
  return addOns.sort(function(a, b) {
    if (!isNaN(a.order) && isNaN(b.order)) return -1;
    if (isNaN(a.order) && !isNaN(b.order)) return 1;
    if (isNaN(a.order) && isNaN(b.order)) return 0;
    return a.order - b.order;
  });
}

export function wrapAddOns(addOnsDb, id, selectedVariantId) {
  const addOnsOnProduct = addOnsDb[id] || {};
  const addOnsOnVariant = addOnsDb[id + '|' + selectedVariantId] || {};

  return Object.assign(addOnsOnProduct, addOnsOnVariant);
}
