const CssClasses = {
  hidden: 'upsell-hidden',
  invisible: 'upsell-invisible',
  container: 'upsell-container',
  addOnContainer: 'upsell-addon-container',
  inline: 'upsell-inline',
  placeholder: 'upsell-addon-placeholder',
  col1: 'upsell-addonbox-col1',
  col2: 'upsell-addonbox-col2',
  col3: 'upsell-addonbox-col3',
  title: 'upsell-addon-title',
  placeholderLine1: 'placeholder-line1',
  placeholderLine2: 'placeholder-line2',
  animatedBackground: 'animated-background',
  cartButton: 'upsell-addToCart',
  hasButtonQtyInput: 'upsell-btn-qty',
  image: 'upsell-addon-img',
  compareAtPrice: 'upsell-compare-at-price',
  hasCompareAtPrice: 'upsell-has-compare-at-price',
  upsellPriceValue: 'upsell-price-value',
  variantDropdown: 'upsell-variant-options',
  header: 'upsell-header',
  addonBox: 'upsell-addonbox',
  defaultAddOnBox: 'upsell-defaultbox',
  imageTop: 'upsell-img-top-addonbox',
  imageLeft: 'upsell-img-left-addonbox',
  qtyWrapper: 'upsell-qty-wrapper',
  qtyWrapperDrop: 'upsell-qty-wrapper-drop',
  qtyBox: 'upsell-qtyBox',
  qtyDropdown: 'upsell-qty-dropdown',
  flexLayout: 'upsell-flex-layout',
  sliderLayout: 'upsell-slider-layout',
  qtyBoxInput: 'upsell-qty-input',
  upsellPrice: 'upsell-price',
  defaultLayout: 'upsell-default',
  cartPageContainer: 'upsell-cartpage-container',
  description: 'upsell-description',
  qtyLabel: 'upsell-qty-label',
  buttonQty: 'upsell-button-qty',
  boldDiscount: 'upsell-bold-discount',
  orderDiscount: 'order-discount',
  originalPriceAttribute: '[data-cart-item-original-price]',
  subtotalPriceBlock: 'upsell-subtotal-price-block',
  customSubtotalOriginalPrice: 'upsell-custom-subtotal-original-price',
  customSubtotalPrice: 'upsell-custom-subtotal-price',
  customRegularPrice: 'upsell-custom-regular-price',
  link: 'upsell-link',
  customBreakLineDawn: 'upsell-break-line-dawn',
  customBreakLine: 'upsell-break-line',
  priceTargetElement: 'upsell-price-target',
  cartSpinner: 'upsell-cart-spinner',
  cartDiscountPriceWrap: 'upsell-cart-discount-price-wrap',
  availableWith: 'upsell-available-with'
};

export default CssClasses;
