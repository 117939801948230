import { getAppProps } from '../utils';

const TRANSLATE_IDS = {
  popUpAddItemText: 1,
  popUpRemoveItemText: 2,
  popUpNoIamGoodText: 3,
  popUpContinueShoppingText: 4,
  popUpAddToCartText: 5,
  popUpSaveText: 6,
  popUpContinueText: 7,
  popUpCheckoutText: 8
};

const TRANSLATE_DATA = {
  [TRANSLATE_IDS.popUpAddItemText]: {
    textId: 'popUpAddItemText',
    defaultText: 'Add'
  },
  [TRANSLATE_IDS.popUpRemoveItemText]: {
    textId: 'popUpRemoveItemText',
    defaultText: 'Remove'
  },
  [TRANSLATE_IDS.popUpNoIamGoodText]: {
    textId: 'popUpNoIamGoodText',
    defaultText: 'No, I’m good'
  },
  [TRANSLATE_IDS.popUpContinueShoppingText]: {
    textId: 'popUpContinueShoppingText',
    defaultText: 'Continue Shopping'
  },
  [TRANSLATE_IDS.popUpAddToCartText]: {
    textId: 'popUpAddToCartText',
    defaultText: 'Add to cart'
  },
  [TRANSLATE_IDS.popUpSaveText]: {
    textId: 'popUpSaveText',
    defaultText: 'Save'
  },
  [TRANSLATE_IDS.popUpContinueText]: {
    textId: 'popUpContinueText',
    defaultText: 'Checkout'
  },
  [TRANSLATE_IDS.popUpCheckoutText]: {
    textId: 'popUpCheckoutText',
    defaultText: 'Check out'
  }
};

function translateText(id) {
  if (!id) {
    return '';
  }

  const data = TRANSLATE_DATA[id];

  if (!data) {
    return '';
  }

  const { options } = getAppProps();

  const { textId, defaultText } = data;

  let isVisible = false;

  if (options[textId] && options[textId].trim().length > 0) {
    isVisible = true;
  }

  return isVisible ? options[textId] : defaultText;
}

export { TRANSLATE_IDS, translateText };
