import loadScript from './loadScript';
import loadCss from './loadcss';
const scriptUri = new URL(document.currentScript.src);
export default function createSlider(callback) {
  if (!window.Flickity) {
    loadCss(`${scriptUri.origin}/flickity.min.css`);
    if (!callback) {
      callback = function() {};
    }
    loadScript(`${scriptUri.origin}/flickity.pkgd.min.js`, callback);
  } else {
    callback();
  }
}
