import CssClasses from '../../template/common/cssClasses';

export default function injectStyles() {
  const css = `
    <style type="text/css">
      .${CssClasses.orderDiscount} {
          font-weight: bold !important;
          color: black !important;
      }
      ${CssClasses.originalPriceAttribute} {
        font-size: 0.92em;
      }
      .${CssClasses.subtotalPriceBlock} {
        display: inline-flex;
        flex-direction: column;
        padding-left: 60px;
      }
      .${CssClasses.hidden}{ 
        display: none; 
      }
      @keyframes ${CssClasses.cartSpinner} {
        to {transform: rotate(360deg);}
      }
      .${CssClasses.cartSpinner} {
        color: #0000 !important;
      }
      .${CssClasses.cartSpinner}:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #ccc;
        border-top-color: #000;
        animation: ${CssClasses.cartSpinner} .6s linear infinite;
      }
    </style>
  `;

  document.querySelector('head').insertAdjacentHTML('beforeend', css);
}
