const UPSELL_DEV_MODE = 'upsell-dev-mode';

export function isDevMode() {
  if (createDevSession()) {
    document.cookie = 'upsell-dev-mode=true';
    return true;
  }
  return document.cookie.indexOf(UPSELL_DEV_MODE) >= 0;
}

function createDevSession() {
  return window.location.href.toLocaleLowerCase().indexOf(UPSELL_DEV_MODE) >= 0;
}
