import { getAddOnProduct, getAppProps, hex2a } from './utils';
const TRACKING_CODE = GA_TRACKING;
const TRACKER_NAME = 'upsellTracker';
let trackerRequired = false;

function getAnalyticsVariable() {
  return window[hex2a('6761')];
}

function sendVariablesData(prefix = '', addOnIds, addOnType, isRemove) {
  const ga = getAnalyticsVariable();

  for (let addOnId in addOnIds) {
    // Get product from addon or from popups (if we dont' have)
    const addOnProduct =
      getAddOnProduct(addOnId, 'addons') || getAddOnProduct(addOnId, 'popups');

    if (addOnProduct) {
      const productList =
        window.upsell.product &&
        window.upsell.product.details &&
        window.upsell.product.details.title;
      ga(`${prefix}ec:addProduct`, {
        id: addOnId,
        name: addOnProduct.title,
        price: addOnProduct.price,
        quantity: addOnIds[addOnId],
        variant: addOnProduct.variant
      });
      ga(`${prefix}ec:setAction`, isRemove ? 'remove' : 'add', {
        list: productList ? productList : 'cart'
      });
      ga(`${prefix}send`, {
        hitType: 'event',
        eventCategory: addOnType,
        eventAction: isRemove ? 'removeFromCart' : 'addToCart',
        eventLabel:
          window.upsell.product && window.upsell.product.details
            ? window.upsell.product.details.id.toString()
            : 'cart'
      });
    }
  }
  // ga(`${TRACKER_NAME}.ec:setAction`, 'purchase', {
  //   id: 'transactionId_123',
  //   revenue: '44.00',
  //   list: 'Test Main Product'
  // });
  // ga(`${TRACKER_NAME}.send`, {
  //   hitType: 'event',
  //   eventCategory: 'upsellPurchase',
  //   eventAction: 'purchase'
  // });
}

function sendUpsellTrackerAnalytics(addOnIds, addOnType, isRemove) {
  const { options } = getAppProps();

  // Skip analytics sending
  if (options.disableGaAnalytics) {
    return;
  }

  // analytics variable
  const ga = getAnalyticsVariable();

  if (!trackerRequired) {
    ga('create', hex2a(TRACKING_CODE), 'auto', TRACKER_NAME);
    ga(`${TRACKER_NAME}.require`, 'ec');
    trackerRequired = true;
  }
  ga(`${TRACKER_NAME}.set`, 'transport', 'beacon');
  ga(`${TRACKER_NAME}.set`, 'dataSource', Shopify.shop);

  sendVariablesData(`${TRACKER_NAME}.`, addOnIds, addOnType, isRemove);
}

function sendUserAnalytics(addOnIds, addOnType, isRemove) {
  sendVariablesData(undefined, addOnIds, addOnType, isRemove);
}

export default function analytics(addOnIds, addOnType, isRemove) {
  if (!getAnalyticsVariable()) {
    return;
  }

  sendUpsellTrackerAnalytics(addOnIds, addOnType, isRemove);
  sendUserAnalytics(addOnIds, addOnType, isRemove);
}
