import analytics from '../analytics';

export function updateAnalytics(items, addOnType) {
  const addOnIds = {};
  items.forEach(v => {
    const id = v.id;
    const quantity = v.quantity;
    addOnIds[id] = quantity;
  });
  const isRemove = false;

  analytics(addOnIds, addOnType, isRemove);
}
