import getAddOnDetails, { prepareAddonDetails } from '../../getAddOnDetails';
import buildAddon from './buildAddon';
import axios from 'axios';
import { getShopName } from '../../utils/shopify';
import { findRecord, getAppProps, setPreparedRecordItems } from '../../utils';
import { ENBABLED_SOLD_OUT } from '../../constants';

function prepareItems(addOns) {
  const items = [];
  addOns.forEach(addOnRecord => {
    if (addOnRecord.ids && addOnRecord.ids.length > 0) {
      items.push(addOnRecord);
    }
  });
  return items;
}

function wrapItems(items) {
  return items.map(v => ({ variantId: v.ids[0] }));
}

function callCallback(callback, html) {
  const allAddOnsHtml = html.join(' ');

  callback(allAddOnsHtml);
}

async function buildAll(args) {
  const {
    items,
    addOns,
    page,
    addToCartButton,
    callback,
    result,
    popupItems
  } = args;
  const { options } = getAppProps();
  const addonsHtmlOrder = new Array(addOns.length);

  let showSoldOut = true; // Show sold out by default.
  if (options.displayOnDisabledCartButton === false) {
    showSoldOut = false;
  }

  if (result) {
    const itemsResult = result.items;
    const popupItemsResult = result.popupItems;

    /* prepare for addons */
    itemsResult.forEach((res, i) => {
      const addOnRecord = findRecord(res.variants, items);

      if (addOnRecord) {
        const addOnDetails = prepareAddonDetails({
          options,
          result: {
            product: res
          },
          addOnRecord
        });

        if (showSoldOut || (!showSoldOut && addOnDetails.quantity > 0)) {
          addonsHtmlOrder[i] = buildAddon({
            addOnDetails,
            addOnRecord,
            page,
            addToCartButton
          });

          window.upsell.addons.push(addOnDetails);
        }
      }
    });

    /* prepare for popups */
    popupItemsResult.forEach(res => {
      const addOnRecord = findRecord(res.variants, items);

      if (addOnRecord) {
        const addOnDetails = prepareAddonDetails({
          options,
          result: {
            product: res
          },
          addOnRecord
        });

        window.upsell.popups.push(addOnDetails);
      }
    });

    callCallback(callback, addonsHtmlOrder);
  } else {
    // Fallback
    const promisesList = [];

    /* popups*/
    for (const [_, addOnRecord] of Object(popupItems).entries()) {
      const promise = new Promise(resolve => {
        getAddOnDetails(addOnRecord)
          .then(addOnDetails => {
            window.upsell.popups.push(addOnDetails);

            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });

      promisesList.push(promise);
    }

    /* items */
    for (const [i, addOnRecord] of Object(items).entries()) {
      const promise = new Promise(resolve => {
        getAddOnDetails(addOnRecord)
          .then(addOnDetails => {
            try {
              if (addOnDetails) {
                addonsHtmlOrder[i] = buildAddon({
                  addOnDetails,
                  addOnRecord,
                  page,
                  addToCartButton
                });

                window.upsell.addons.push(addOnDetails);
              }

              resolve(true);
            } catch (err) {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });

      promisesList.push(promise);
    }

    Promise.all(promisesList).then(() => {
      callCallback(callback, addonsHtmlOrder);
    });
  }
}

export default function buildaddOnsHtml(
  addToCartButton,
  addOns = [],
  popups = [],
  page,
  callback
) {
  if (!window.upsell) window.upsell = {};

  window.upsell.addons = [];
  window.upsell.popups = [];

  const items = prepareItems(addOns);
  const popupItems = prepareItems(popups);

  // Save our records
  setPreparedRecordItems({
    items,
    popupItems
  });

  const build = result =>
    buildAll({
      result,
      items,
      popupItems,
      addOns,
      page,
      addToCartButton,
      callback
    });

  if (ENBABLED_SOLD_OUT) {
    // Get items with quantity
    const shopname = getShopName(true);
    axios
      .post(
        QUANTITY_API_URL,
        {
          items: wrapItems(items),
          popupItems: wrapItems(popupItems),
          shop: shopname
        },
        { timeout: 10 * 1000 } // timeout 10 secs -> catch block.
      )
      .then(res => {
        const { error, errCode, result } = res.data;
        if (error) {
          console.log('err', errCode);
          // Fallback
          build();
          return;
        }

        // Render with quantity result
        build(result);
      })
      .catch(err => {
        console.log('err', err);
        // Fallback
        build();
      });
  } else {
    build();
  }
}
