import { getPopUpData } from './utils';
import { initPopUp } from '../../template/components/popUp/index';
import { getAppProps } from '../../utils';
import { hookBuyItNow } from './buyItNow';
import { getForm, getSubmitButton } from '../../render/common/utils';
import { PAGE } from '../../domain';
import Wrapper from '../../template/components/popUp/wrapper';
import hookInstall from '../../utils/hookInstall';
import { initCartDrawer } from './cartDrawer';

function initOtherHook() {
  const { options } = getAppProps();

  const page = PAGE.Product;
  const cartForm = getForm(page, options);
  if (!cartForm) {
    return false;
  }

  const submitEl = getSubmitButton(options, cartForm, page);
  if (!submitEl) {
    return false;
  }

  const wrap = new Wrapper();

  // Override event
  let shownOnce = false;

  // Replace
  let btnReplace = submitEl;
  if (options.overrideSubmitButton) {
    jUpsell(submitEl).replaceWith(jUpsell(submitEl).clone());

    btnReplace = getSubmitButton(options, cartForm, page);
  }

  btnReplace.addEventListener('click', e => {
    if (shownOnce) return;

    const variants = getPopUpData();

    if (variants !== null) {
      shownOnce = true;
      initPopUp(wrap);
      e.preventDefault();
      return;
    }
  });

  return true;
}

async function setupShopifyAddToCartHook() {
  hookInstall(initOtherHook);
  hookInstall(initCartDrawer);
  hookInstall(hookBuyItNow);
}

export default setupShopifyAddToCartHook;
