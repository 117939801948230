export function getCartItems() {
  return new Promise(resolve => {
    jUpsell
      .getJSON('/cart.js', function(cart) {
        resolve(cart.items);
      })
      .fail(() => {
        resolve([]);
      });
  });
}

export async function updateCartProperties(attr) {
  return fetch('/cart/update.js', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(attr)
  });
}
