import { getAppProps, isAjaxProductPageHook, isProductPage } from '../../../utils';
import { getPopUpData } from '../../../pages/productPageInjector/utils';

export function setPopupsLoaded(state) {
  window.upsellPopupsLoaded = state;
}
export function isPopupsLoaded() {
  return window.upsellPopupsLoaded;
}

export function isAddonStored(id) {
  if (!window.upsellSelectedAddons) return false;

  if (window.upsellSelectedAddons[id]) {
    return true;
  }

  return false;
}

export function storeSelectedAddon(id, args = {}) {
  if (!window.upsellSelectedAddons) {
    window.upsellSelectedAddons = {};
  }

  if (!window.upsellSelectedAddons[id]) {
    window.upsellSelectedAddons[id] = { id, ...args };
  }
}

export function removeSelectedAddon(id) {
  if (!window.upsellSelectedAddons) return;

  if (window.upsellSelectedAddons[id]) {
    delete window.upsellSelectedAddons[id];
  }
}

export function isShowMainProduct() {
  // if product not found
  const { product } = getPopUpData();
  if (!product) {
    return false;
  }

  // If we use Ajax handler, skip main product (it's already added)
  if (isProductPage() && isAjaxProductPageHook()) {
    return false;
  }

  const { options } = getAppProps();
  if (options.displayMainProductOnPopUp === false) {
    return false;
  }

  return true;
}
