import { trackVariant, isVariant, createAddOnRecord } from './addOnsUtil';
export default function getCatalogAddOns(
  addOnsDb,
  addOnsTracker,
  addOnsToIgnore,
  groupVairantsTracker
) {
  const addOns = [];
  const addOnsForAllProducts = addOnsDb.all;
  if (addOnsForAllProducts) {
    for (let addOn in addOnsForAllProducts) {
      if (groupVairantsTracker && isVariant(addOn)) {
        trackVariant(
          addOnsForAllProducts,
          groupVairantsTracker,
          addOn,
          addOnsToIgnore
        );
      } else {
        const currentAddOnRecord = createAddOnRecord(
          addOnsForAllProducts,
          addOn
        );
        const addOnToIgnore = groupVairantsTracker
          ? addOnsToIgnore[currentAddOnRecord.handle]
          : addOnsToIgnore[currentAddOnRecord.ids[0]];
        if (!addOnsTracker[addOn] && !addOnToIgnore) {
          addOnsTracker[addOn] = true;
          addOns.push(createAddOnRecord(addOnsForAllProducts, addOn));
        }
      }
    }
  }
  return addOns;
}
