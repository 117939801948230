import { getAppProps } from '../../utils';
import { PAGE } from '../../domain';
import { getSubmitButton } from '../../render/common/utils';
import { callNextStep } from './common';

function getForm() {
  const allForms = document.querySelectorAll(
    'form[action="/cart"][method="POST"]'
  );
  if (allForms.length > 0) {
    return allForms[0];
  }

  return null;
}

const hookedProp = 'upsell-hooked-cart-drawer-button';

function addCartDrawerHook() {
  if (document.querySelector(`[${hookedProp}="true"]`)) {
    return false;
  }

  const cartForm = getForm();
  if (!cartForm) return false;

  const { options } = getAppProps();

  const page = PAGE.Cart;
  const cartButton = getSubmitButton(options, cartForm, page);
  if (!cartButton) {
    return false;
  }

  cartButton.setAttribute(hookedProp, 'true');
  cartButton.addEventListener('click', e => {
    e.preventDefault();

    // Call our discount api
    callNextStep();
  });

  return true;
}

var observeDOM = (function() {
  var MutationObserver =
    window.MutationObserver || window.WebKitMutationObserver;

  return function(obj, callback) {
    if (!obj || obj.nodeType !== 1) return;

    if (MutationObserver) {
      // define a new observer
      var mutationObserver = new MutationObserver(callback);

      // have the observer observe foo for changes in children
      mutationObserver.observe(obj, { childList: true, subtree: true });
      return mutationObserver;
    }

    // browser support fallback
    else if (window.addEventListener) {
      obj.addEventListener('DOMNodeInserted', callback, false);
      obj.addEventListener('DOMNodeRemoved', callback, false);
    }
  };
})();

function onDomChange() {
  const el = document.querySelector('body');
  observeDOM(el, function(m) {
    var addedNodes = [];

    m.forEach(
      record => record.addedNodes.length & addedNodes.push(...record.addedNodes)
    );

    if (addedNodes.length > 0) {
      // Try add hook again
      addCartDrawerHook();
    }
  });
}

export async function initCartDrawer() {
  addCartDrawerHook();
  onDomChange();

  return true;
}
