let data = null;

export function setPopUpData(args) {
  data = args;
}

export function getPopUpData() {
  return data;
}

window.upsellSetPopUpData = setPopUpData;
window.upsellGetPopUpData = getPopUpData;
