import CssClasses from '../common/cssClasses';

function placeholder() {
  return `<div class="${CssClasses.addonBox} ${CssClasses.placeholder}">
              <div class="${CssClasses.col1} ${CssClasses.animatedBackground}">
              </div>
              <div class="${CssClasses.col2}">
                  <div class="${CssClasses.placeholderLine1} ${CssClasses.animatedBackground}"></div>
                  <div class="${CssClasses.placeholderLine2} ${CssClasses.animatedBackground}"></div>
              </div>
          </div>`;
}

export default placeholder;
