import { redirectCartPage } from '../../../utils/redirect';
import { getAppProps, getParameterByName } from '../../../utils';
import { createAddOnRecord } from '../../../addOnsUtil';
import { getHookCheckoutItems } from '../utils';
import { xhrIntercept } from '../../../utils/xhr';
import { getCartItems } from '../../../api/common';
import { injectCart } from './themes/utils';

let forceRedirect = false;

let oldState = [];
const TIMER = 1 * 1500;

const startUpdEvent = new CustomEvent('onCartItemsStartUpdate');
const updEvent = new CustomEvent('onCartItemsUpdate');

const callStartUpdEvent = () => document.dispatchEvent(startUpdEvent);
const callUpdEvent = () => document.dispatchEvent(updEvent);

function isChanged(oldState, newState) {
  if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
    return true;
  }

  return false;
}

function isItemExists(id, items) {
  if (items.find(v => v.id === id)) {
    return true;
  }
  return false;
}

async function checkAndRemoveItemAttached(collection, key, oldState) {
  const parsed = createAddOnRecord(collection, key);
  const { ids, specified } = parsed;

  // Should remove from cart?
  if (specified && isItemExists(ids[0], oldState)) {
    await jUpsell.ajax({
      url: '/cart/change.js',
      dataType: 'json',
      data: {
        quantity: 0,
        id: ids[0]
      },
      type: 'POST',
      xhrFields: {
        withCredentials: true
      }
    });
    return true;
  }
  return false;
}

async function checkCartChangeInAddon(addOnsDb, oldState, newState) {
  let refreshPage = false;

  for (const itemOld of oldState) {
    // Check state diff
    const id = itemOld.id;
    const founded = newState.find(v => v.id === id);

    if (!founded) {
      const productId = itemOld.product_id;
      // If we have products attached to upsell main product
      // Check first for product
      if (addOnsDb[`${productId}`]) {
        const iterCollection = addOnsDb[`${productId}`];
        for (const key in iterCollection) {
          if (await checkAndRemoveItemAttached(iterCollection, key, oldState)) {
            refreshPage = true;
          }
        }
      }

      // Check in product variant
      if (addOnsDb[`${productId}|${id}`]) {
        const iterCollection = addOnsDb[`${productId}|${id}`];
        for (const key in iterCollection) {
          if (await checkAndRemoveItemAttached(iterCollection, key, oldState)) {
            refreshPage = true;
          }
        }
      }
    }
  }

  return refreshPage;
}

async function checkAndRemove(oldState, newState) {
  const { addOnsDb, popups } = getAppProps();

  // Check popups changes
  const popupsChanged = await checkCartChangeInAddon(
    popups,
    oldState,
    newState
  );

  // Check addon changes
  const addonsChanged = await checkCartChangeInAddon(
    addOnsDb,
    oldState,
    newState
  );

  if (popupsChanged || addonsChanged) {
    redirectCartPage();
  }
}

export async function checkCartChangesHook() {
  oldState = getHookCheckoutItems();

  // Check state before all
  await checkAndRemove([], oldState);
}

async function updateCartChanges() {
  const newState = await getCartItems();
  if (isChanged(oldState, newState)) {
    callStartUpdEvent();
    callUpdEvent();
    await checkAndRemove(oldState, newState);

    oldState = newState;
  }
}

export async function initCartChangesHook() {
  if (Proxy) {
    window.fetch = new Proxy(window.fetch, {
      apply(fetch, that, args) {
        if (args[0] && args[0].includes('/cart/change')) {
          callStartUpdEvent();
        }
        const result = fetch.apply(that, args);
        result.then(async response => {
          if (args[0].includes('/cart/change')) {
            const newState = await getCartItems();

            if (isChanged(oldState, newState)) {
              callUpdEvent();
              await checkAndRemove(oldState, newState);

              oldState = newState;
            }
          }
        });

        return result;
      }
    });
  } else {
    window.setInterval(async () => {
      await updateCartChanges();
    }, TIMER);
  }

  // Hook via intercept
  xhrIntercept('/cart/change.js', async function() {
    await updateCartChanges();

    if (forceRedirect) {
      redirectCartPage();
    }
  });

  // Hook href links
  injectCartChangesHook();
}

export function injectCartChangesHook() {
  const injectInfo = injectCart();

  // Hook all a href links
  if (injectInfo && injectInfo.aTags) {
    const aTags = injectInfo.aTags;
    aTags.forEach(value => {
      const line = getParameterByName('line', value.href);
      const id = getParameterByName('id', value.href);

      // Hook remove
      value.addEventListener('click', async e => {
        e.preventDefault();

        // Set force redirect to true, we must anyway refresh the page
        forceRedirect = true;

        // Send info (should catch xhrIntercept('/cart/change.js') hook)
        jUpsell.ajax({
          url: '/cart/change.js',
          dataType: 'json',
          data: {
            quantity: 0,
            ...(line ? { line } : { id })
          },
          type: 'POST',
          xhrFields: {
            withCredentials: true
          }
        });
      });
    });
  }
}
