import { styles } from './style';
import { render } from './render';
import {
  upsellInputPrefix,
  modalId,
  popUpId,
  getGroupedVariantId
} from './data';
import {
  getAppProps,
  getMainProductQuantity,
  getMainProductVariant,
  isDawnTheme
} from '../../../utils';
import {
  isAddonStored,
  isPopupsLoaded,
  isShowMainProduct,
  removeSelectedAddon,
  setPopupsLoaded,
  storeSelectedAddon
} from './common';
import { updateAnalytics } from '../../../utils/analytics';
import { TRANSLATE_IDS, translateText } from '../../../utils/translate';

let visible = false;
let themeInstance = null;
let updateInterval = null;

function setThemeInstance(instance) {
  themeInstance = instance;
}

function getThemeInstance() {
  return themeInstance;
}

function outsideClick(event, notelem) {
  notelem = jUpsell(notelem); // jquerize (optional)
  // check outside click for multiple elements
  var clickedOut = true,
    i,
    len = notelem.length;
  for (i = 0; i < len; i++) {
    if (event.target == notelem[i] || notelem[i].contains(event.target)) {
      clickedOut = false;
    }
  }
  if (clickedOut) return true;
  else return false;
}

function clickHandler(e) {
  var modal = document.getElementById(modalId);

  if (outsideClick(e, modal)) {
    addPopUpMainProduct();
  }
}

function closePopUp(callEvent) {
  if (visible) {
    document.getElementById(popUpId).outerHTML = '';
    visible = false;

    window.removeEventListener('click', clickHandler);

    // clean up
    window.upsellSelectedAddons = {};

    if (updateInterval) {
      window.clearInterval(updateInterval);
    }

    if (callEvent) {
      const instance = getThemeInstance();
      if (instance && instance.onClose) {
        instance.onClose();
      }
    }
  }
}

function popupRender(forceRender) {
  const html = `
    ${styles()}
    ${render()}
  `;

  // Shall we re-render?
  if (forceRender) {
    const div = document.getElementById(popUpId);
    div.innerHTML = html;

    return;
  }

  var div = document.createElement('div');
  div.id = popUpId;
  div.innerHTML = html;
  document.body.insertBefore(div, document.body.firstChild);
}

function showPopUp(instance) {
  if (visible) {
    closePopUp();
  }

  setThemeInstance(instance);
  popupRender();

  if (!isPopupsLoaded()) {
    const startTime = window.performance.now();
    const timeoutTime = 5000;

    updateInterval = window.setInterval(() => {
      if (isPopupsLoaded()) {
        popupRender(true);
        window.clearInterval(updateInterval);
        updateInterval = null;
      }

      // If is timeout, remove loading spinner
      const curTime = window.performance.now();
      if (curTime - startTime > timeoutTime) {
        setPopupsLoaded(true);
      }
    }, 100);
  }

  // Fix close bug after showing (click event is so fast)
  window.setTimeout(() => {
    window.addEventListener('click', clickHandler);
  }, 100);

  visible = true;
}

function setPopUpItemState(id, state) {
  const { options } = getAppProps();
  const type = options.popUpAddOnType;

  if (type === 'button' || type === 'buttonQtyBox') {
    const div = jUpsell('#upsell-product-' + id);

    if (state) {
      div.removeClass('added');
    } else {
      div.addClass('added');
    }

    div.find(jUpsell('.checkmark')).css({ display: state ? 'none' : 'block' });
    div.find(jUpsell('.upsell-now-quantity-field')).prop('disabled', !state);
    div
      .find(jUpsell('.upsell-remove-button'))
      .css({ display: state ? 'none' : 'block' });
    div
      .find(jUpsell('.upsell-product-add-to-cart span'))
      .text(
        state
          ? translateText(TRANSLATE_IDS.popUpAddItemText)
          : translateText(TRANSLATE_IDS.popUpRemoveItemText)
      );
  }
}

/* STORE */
function prepareArgs(args) {
  const handle = args.id;
  let id = getGroupedVariantId(args);
  args.id = parseInt(id);
  args.handle = handle;
  return { data: args, id, handle };
}

function togglePopUpAddonItem(args) {
  const { handle, id, data } = prepareArgs(args);
  if (!isAddonStored(id)) {
    storeSelectedAddon(id, data);
    setPopUpItemState(handle, false);
  } else {
    removeSelectedAddon(id);
    setPopUpItemState(handle, true);
  }
}

function addPopUpAddonItem(args) {
  const { handle, id, data } = prepareArgs(args);
  storeSelectedAddon(id, data);
  setPopUpItemState(handle, false);
}

function removePopUpAddonItem(args) {
  const { handle, id } = prepareArgs(args);
  removeSelectedAddon(id);
  setPopUpItemState(handle, true);
}

function addPopUpMainProduct() {
  const instance = getThemeInstance();

  if (instance) {
    const items = [];

    // main product
    if (isShowMainProduct()) {
      const quantity = getMainProductQuantity();

      const variantId = getMainProductVariant();
      items.push({
        id: parseInt(variantId),
        quantity
      });
    }

    queryAddToCart(items);
  }

  // hide window
  closePopUp();
}

function getSelectedPopUpAddons() {
  const items = [];
  const list = window.upsellSelectedAddons;

  for (let key in list) {
    const v = list[key];

    const { id, input, handle } = v;
    let quantity = 1;

    if (input) {
      quantity = parseInt(
        document.getElementById(`${upsellInputPrefix}${handle}`).value
      );
    }

    if (quantity > 0) {
      items.push({
        id: parseInt(id),
        quantity
      });
    }
  }

  return items;
}

function addPopUpSelectProducts() {
  // add all selected up-sell addons to cart
  const instance = getThemeInstance();
  if (instance) {
    const items = [];

    // main product
    if (isShowMainProduct()) {
      const quantity = getMainProductQuantity();

      const variantId = getMainProductVariant();
      items.push({
        id: parseInt(variantId),
        quantity
      });
    }

    // popup addons
    const popupAddons = getSelectedPopUpAddons();

    if (popupAddons) {
      popupAddons.forEach(v => {
        items.push(v);
      });
    }

    queryAddToCart(items);
  }

  // hide window
  closePopUp();
}

function getAvialableSelectedAddons() {
  const selectedItemsObj = window.upsellGetSelectedAddons();

  const addons = [];

  for (const variantId in selectedItemsObj) {
    const quantity = parseInt(selectedItemsObj[variantId]);

    if (quantity > 0) {
      addons.push({
        id: parseInt(variantId),
        quantity
      });
    }
  }

  return addons;
}

function combineWithSelectedAddons(addons) {
  // get previosly selected addons
  const selectedAddons = getAvialableSelectedAddons();
  if (selectedAddons) {
    selectedAddons.forEach(v => {
      const id = v.id;
      const newQuantity = v.quantity;

      const foundedAddon = addons.find(addon => addon.id === id);

      if (foundedAddon) {
        foundedAddon.quantity = foundedAddon.quantity + newQuantity;
      } else {
        addons.push(v);
      }
    });
  }
}

function queryAddToCart(items) {
  const instance = getThemeInstance();

  // Add selected previously addons
  combineWithSelectedAddons(items);

  // If is not empty - send to the server
  if (items.length > 0) {
    const data = JSON.stringify({ items });
    instance._addItemToCartFinal(data, 'application/json');
  } else {
    instance.onEmpty();
  }

  // Update analytics stuff
  updateAnalytics(items, 'popup');
}

/* API */
window.upsellClosePopUp = closePopUp;
window.upsellShowPopUp = showPopUp;
window.upsellAddPopUpAddonItem = addPopUpAddonItem;
window.upsellRemovePopUpAddonItem = removePopUpAddonItem;
window.upsellAddPopUpMainProduct = addPopUpMainProduct;
window.upsellAddPopUpSelectProducts = addPopUpSelectProducts;
window.upsellGetSelectedPopUpAddons = getSelectedPopUpAddons;
window.upsellGetAvialableSelectedAddons = getAvialableSelectedAddons;
window.upsellAddPopUpToggleItem = togglePopUpAddonItem;
window.upsellIsShowMainProduct = isShowMainProduct;
window.upsellCombineWithSelectedAddons = combineWithSelectedAddons;
window.upsellSetPopUpItemState = setPopUpItemState;
window.upsellRemovePopUpStoreAddon = removeSelectedAddon;
window.upsellAddPopUpStoreAddon = storeSelectedAddon;

export {
  closePopUp,
  showPopUp,
  addPopUpAddonItem,
  togglePopUpAddonItem,
  removePopUpAddonItem,
  addPopUpMainProduct,
  addPopUpSelectProducts,
  setThemeInstance,
  getThemeInstance,
  isShowMainProduct
};
