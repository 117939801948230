import { cartDetailsUrl } from '../../currentCart';
import errorHandler from '../../errorHandler';
import { getItemDiscount } from '../../utils/item';
import setupShopifyCartHook from './hook';
import injectStyles from './styles';
import { setHookCheckoutItems } from './utils';
import {
  injectCart,
  injectLineSubtotal,
  injectSubtotal
} from './hooks/themes/utils';
import { checkCartChangesHook } from './hooks/cartChanges';

function getData() {
  return new Promise(resolve => {
    try {
      jUpsell.getJSON(cartDetailsUrl, function(result) {
        resolve(result);
      });
    } catch (err) {
      errorHandler(err);
    }
  });
}

function setup() {
  // Get cart data from Shopify's API
  getData()
    .then(v => {
      let subtotalOriginal = 0;
      let subtotal = 0;
      const items = v.items;

      const injectInfo = injectCart();

      setHookCheckoutItems(items);
      items.forEach((v, i) => {
        const { price, quantity } = v;

        let discount = getItemDiscount(v);

        const correctedPrice = price / 100;
        const qt = quantity;

        const iter = i + 1;

        const costOld = correctedPrice;
        const cost = correctedPrice - (correctedPrice / 100) * discount;
        const totalPriceOld = costOld * qt;
        const totalPrice = cost * qt;

        const totalOriginalPrice = correctedPrice * qt;

        if (discount > 0) {
          injectLineSubtotal({
            item: v,
            iter,
            cost,
            costOld,
            totalPrice,
            totalPriceOld,
            injectInfo
          });
        }

        subtotalOriginal = subtotalOriginal + totalOriginalPrice;
        subtotal = subtotal + totalPrice;
      });

      // Inject only if we have discounts && info
      if (injectInfo && subtotal !== subtotalOriginal) {
        injectSubtotal({
          subtotalOriginal,
          subtotal,
          subtotalElement: injectInfo.subtotalElement
        });
        // Remove loading anim
        // injectLoadingAnim(injectInfo, false);
      }
      // Check changes
      checkCartChangesHook();
    })
    .catch({});
}

export default function cartPageInjector() {
  injectStyles();
  setupShopifyCartHook();

  // Update cart info -> inject
  setup();

  // On cart start change
  document.addEventListener(
    'onCartItemsStartUpdate',
    () => {
      // const injectInfo = injectCart();
      // injectLoadingAnim(injectInfo, true);
    },
    false
  );

  // On cart update
  document.addEventListener(
    'onCartItemsUpdate',
    () => {
      // Update all again
      setup();
    },
    false
  );
}
