import { Element_Ids, priceHtml, TOKENS } from './templates';
import {
  getAppProps,
  getAvailableWithTitle,
  getOtherAddonDetails,
  getPreparedAddonRecordItems,
  getPriceDetails
} from './utils';
import {
  removeAddOnIds,
  addAddOnId,
  selectedAddOns
} from './cartActionHandler';
import { adjustProductPrice } from './priceUpdater';
import { getItemPropertyById } from './utils/item';
import CssClasses from './template/common/cssClasses';
export function variantSelectionHandler() {
  window.upsell = window.upsell || {};
  window.upsell.changeVariant = function(
    productHandle,
    variantOptionsJson,
    selectedVariantId
  ) {
    const { options, addOnsDb } = getAppProps();

    const variantOptions = JSON.parse(decodeURIComponent(variantOptionsJson));
    const variantOption = variantOptions.find(function(variantOption) {
      return variantOption.id === parseInt(selectedVariantId);
    });
    const addOnContainer = document.querySelector(
      `#${Element_Ids.addOnItemContainer}${productHandle}`
    );
    const aTags = addOnContainer.querySelectorAll('a');
    for (let i = 0; i < aTags.length; i++) {
      aTags[i].setAttribute('href', variantOption.url);
    }
    const imgSrc = options.useThumbnailImage
      ? variantOption.originalSmallImage
      : variantOption.image;
    addOnContainer.querySelector('img').setAttribute('src', imgSrc || '');

    const priceDetails = getPriceDetails(
      options,
      variantOption.price,
      variantOption.compareAtPrice
    );

    const priceElement = addOnContainer.querySelector(
      `.${Element_Ids.priceTargetElement}`
    );
    const { items: records } = getPreparedAddonRecordItems();

    const optionId = variantOption.id;
    const addOnRecord = records.find(v => v.ids.includes(optionId)) || {
      discount: 0
    };

    const item = {
      variant_id: optionId
    };

    const {
      hasCompare,
      compareAtPrice,
      finalPrice,
      discount
    } = getOtherAddonDetails({
      priceDetails,
      addOnRecord
    });

    // Update price element
    priceElement.innerHTML = priceHtml({
      hasCompare,
      compareAtPrice,
      finalPrice
    }).replace(TOKENS.discount, discount);

    // Update available with
    const descContainer = document.querySelector(
      `#${Element_Ids.descBlockContainer}${productHandle}`
    );
    const availableWithElement = descContainer.querySelector(
      `.${CssClasses.availableWith}`
    );
    if (availableWithElement) {
      const state = getItemPropertyById(addOnsDb, item, 'availableWith', false);
      availableWithElement.innerHTML = state ? getAvailableWithTitle() : '';
    }

    resetAddToCartButton(options, addOnContainer);
    resetCheckBox(options, addOnContainer, variantOptions, selectedVariantId);
    resetQtyBox(options, addOnContainer, variantOptions, selectedVariantId);
    resetQtyDropdown(
      options,
      addOnContainer,
      variantOptions,
      selectedVariantId
    );
  };
}

function resetSelectedAddOns(options, variantOptions, selectedVariantId, qty) {
  const ids = variantOptions.map(function(variantOption) {
    return variantOption.id;
  });
  removeAddOnIds(ids);
  addAddOnId(selectedVariantId, qty);
}

function resetAddToCartButton(options, addOnContainer) {
  if (options.addOnType === 'button') {
    const addToCartButton = jUpsell(addOnContainer.querySelector('button'));
    const isRemove = addToCartButton.data('upsell-remove');
    if (isRemove) {
      addToCartButton.data('upsell-remove', !isRemove);
      const text = addToCartButton.data('upsell-addText');
      if (text) {
        addToCartButton.text(text);
      }
    }
  }
}

function resetCheckBox(
  options,
  addOnContainer,
  variantOptions,
  selectedVariantId
) {
  if (options.addOnType === 'checkbox') {
    const checkbox = addOnContainer.querySelector('input[type="checkbox"]');
    if (checkbox && checkbox.checked) {
      resetSelectedAddOns(options, variantOptions, selectedVariantId, 1);
      adjustProductPrice(selectedAddOns(), options);
    }
  }
}

function resetQtyBox(
  options,
  addOnContainer,
  variantOptions,
  selectedVariantId
) {
  if (options.quantitySelect) {
    const qtyBox = addOnContainer.querySelector('input[type="number"]');
    if (qtyBox && parseInt(qtyBox.value) > 0) {
      resetSelectedAddOns(
        options,
        variantOptions,
        selectedVariantId,
        qtyBox.value
      );
    }
  }
}

function resetQtyDropdown(
  options,
  addOnContainer,
  variantOptions,
  selectedVariantId
) {
  if (options.addOnType === 'dropdown') {
    const qtyBox = addOnContainer.querySelector('.upsell-qty-dropdown');
    if (qtyBox && parseInt(qtyBox.value) > 0) {
      resetSelectedAddOns(
        options,
        variantOptions,
        selectedVariantId,
        qtyBox.value
      );
    }
  }
}
