import { CssClasses, STYLE_TOKENS, TOKENS } from '../../templates';

export default function buildContainer(containerTemplate, options) {
  return containerTemplate
    .replace(TOKENS.headerText, options.headerText)
    .replace(
      STYLE_TOKENS.header,
      options.showHeaderText && options.headerText ? '' : CssClasses.hidden
    );
}
