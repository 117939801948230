import { getHookCheckoutItems } from '../utils';
import { redirectCheckoutPage } from '../../../utils/redirect';
import {
  getAppProps,
  getAttributesForm,
  getNoteText,
  isRenderCartAddons,
  setMouseLoading
} from '../../../utils';
import { ENABLED_DISCOUNTS } from '../../../constants';
import { getForm, getSubmitButton } from '../../../render/common/utils';
import { PAGE } from '../../../domain';
import Wrapper from '../../../template/components/popUp/wrapper';
import { getPopUpData } from '../../productPageInjector/utils';
import { initPopUp } from '../../../template/components/popUp';
import showInfo from '../../../utils/info';
import { getActualAddons } from '../../../template/components/popUp/data';
import { discountApi } from '../../../api/discount';
import { updateCartProperties } from '../../../api/common';

async function callDiscountAPI(selectedItems) {
  // Apply notes (fix for some users with bad themes)
  const text = getNoteText();
  const page = PAGE.Cart;
  const { options } = getAppProps();
  const cartForm = getForm(page, options);
  const attr = getAttributesForm(jUpsell(cartForm));
  if (
    Object.keys(attr).length > 0 ||
    (typeof text === 'string' && text.length > 0)
  ) {
    setMouseLoading(true);

    const props = {};

    if (text) {
      props.note = text;
    }

    if (attr) {
      props.attributes = attr;
    }

    await updateCartProperties(props);
  }

  const items = getHookCheckoutItems();

  // Add popup items
  if (selectedItems) {
    const popups = getActualAddons();

    selectedItems.forEach(v => {
      let item = null;

      for (const iter of popups) {
        if (iter.variantOptions) {
          let founded = null;
          iter.variantOptions.forEach(variant => {
            if (variant.id === v.id) {
              founded = variant;
            }
          });
          if (founded) {
            item = founded;
            break;
          }
        } else {
          if (iter.id === v.id) {
            item = iter;
            break;
          }
        }
      }

      if (item) {
        items.push({
          product_id: item.productId,
          variant_id: item.id,
          id: item.id,
          quantity: v.quantity,
          type: 'popup'
        });
      }
    });
  }

  discountApi(items);
}

function callNextStep(selectedItems) {
  if (ENABLED_DISCOUNTS) {
    callDiscountAPI(selectedItems);
  } else {
    redirectCheckoutPage();
  }
}

export default function initButtonHandler() {
  if (!isRenderCartAddons()) {
    return true;
  }

  const { options } = getAppProps();

  const page = PAGE.Cart;
  const cartForm = getForm(page, options);
  if (!cartForm) {
    return false;
  }

  const submitEl = getSubmitButton(options, cartForm, page);
  if (!submitEl) {
    return false;
  }

  const wrap = new Wrapper({
    useContinueText: true
  });

  wrap.onAddToCart = data => {
    showInfo('Added to cart', options);

    // eslint-disable-next-line no-undef
    setTimeout(() => {
      callNextStep(data && data.items);
    }, 1500);
  };

  wrap.onEmpty = () => {
    callNextStep();
  };

  // Override event
  let shownOnce = false;

  submitEl.addEventListener('click', e => {
    // Skip our app if some app overrides click (cancel's it)
    if (e.defaultPrevented) {
      return;
    }

    if (shownOnce) {
      callNextStep();
      e.preventDefault();
      return;
    }

    const variants = getPopUpData();

    if (variants !== null) {
      shownOnce = true;

      initPopUp(wrap);
      e.preventDefault();
      return;
    }

    callNextStep();
    e.preventDefault();
  });

  return true;
}
