import loadScript from './loadScript';
import * as cache from './cache';
import { isProductPage, isCartPage, setAppProps } from './utils';
import errorHandler from './errorHandler';
import { isDevMode } from './devMode';
import productPage from './pages/productPage';
import cartPage from './pages/cartPage';
import cartPageInjector from './pages/cartPageInjector/index';
import { getShopName } from './utils/shopify';
import productPageInjector from './pages/productPageInjector';
import { setPopupsLoaded } from './template/components/popUp/common';
import { popupVariantSelectionHandler } from './template/components/popUp/variantSelection';
import injectThemeEditorStyles from './render/common/injectThemeEditorStyles';

export default function app() {
  // Init important stuff.
  String.prototype.insertAt = function(index, string) {
    return `${this.substr(0, index)}${string}${this.substr(index)}`;
  };
  String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };

  getPredsAndAddOns(function(response) {
    const options = response.options;
    const addOnsDb = response.addons;
    const popups = response.popups;

    if (!addOnsDb || (options.disableApp && !isDevMode())) return;

    // Define variables if not founded
    if (!window.upsell) {
      window.upsell = {};
    }

    // Load all poups (not ready)
    setPopupsLoaded(false);

    // Store all needed data
    setAppProps({
      options,
      addOnsDb,
      popups
    });

    if (
      isProductPage() &&
      (!options.noproduct || options.noproduct === 'false')
    ) {
      productPage();
      productPageInjector();
    } else if (isCartPage()) {
      cartPage();
      cartPageInjector();
    }

    popupVariantSelectionHandler();
    injectThemeEditorStyles();
  });
}

function getPredsAndAddOns(callBack) {
  if (!isDevMode() && loadFromCache(callBack)) {
    return;
  }

  const shopname = getShopName();
  const config = {
    apiKey: API_KEY,
    authDomain: AUTH_DOMAIN,
    databaseURL: DATABASE_URL,
    projectId: PROJECT_ID
  };
  loadScript(
    'https://www.gstatic.com/firebasejs/6.6.1/firebase-app.js',
    function() {
      loadScript(
        'https://www.gstatic.com/firebasejs/6.6.1/firebase-database.js',
        function() {
          //Some Apps are really naugthy and have process as a globa var
          //we are just going to rest it
          window.process = undefined;
          const addOnApp = firebase.initializeApp(config, 'addOnApp');

          const root = addOnApp
            .database()
            .ref(shopname)
            .limitToFirst(3);

          root.once('value', function(snapshot) {
            try {
              const rootVal = snapshot.val();
              if (rootVal) {
                cache.set(
                  rootVal.prefs,
                  rootVal.addons || {},
                  rootVal.popups || {}
                );
                const initOptions = {
                  ...getDefaultOpitons(),
                  ...rootVal.prefs
                };
                callBack({
                  options: initOptions,
                  addons: rootVal.addons || {},
                  popups: rootVal.popups || {}
                });
              }
            } catch (err) {
              errorHandler(err);
            }
          });
        }
      );
    }
  );
}

function loadFromCache(callBack) {
  const cached = cache.get();
  if (cached && cached.options && cached.addons && cached.popups) {
    callBack(cached);
    return true;
  }
  return false;
}

function getDefaultOpitons() {
  return {
    addOnType: 'button',
    showDetailsLink: false,
    showPlus: true,
    bold: true,
    showBorder: false,
    textColor: '000000',
    displayOnDisabledCartButton: true,
    popUpAddOnType: 'button',
    popUpLinkTitle: true
  };
}
