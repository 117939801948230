const TIMEOUT_MINUTES = 15;
const CACHE_BUST_KEY = 'upsell-cache-clear';
const KEYS = {
  options: 'upsell-options',
  addOns: 'upsell-addOns',
  timestamp: 'upsell-cache-timestamp',
  popups: 'upsell-popups'
};
export function get() {
  try {
    if (!window.sessionStorage) return;
    if (isTimedOut()) return;
    if (bustCache()) {
      clear();
      return;
    }

    const optionsVal = sessionStorage.getItem(KEYS.options);
    const addOnsVal = sessionStorage.getItem(KEYS.addOns);
    const popupsVal = sessionStorage.getItem(KEYS.popups);

    if (optionsVal && addOnsVal) {
      return {
        options: JSON.parse(optionsVal),
        addons: JSON.parse(addOnsVal),
        popups: JSON.parse(popupsVal)
      };
    }
  } catch (ex) {
    return;
  }
}
export function set(options, addOns, popups) {
  if (bustCache()) return;

  if (options && addOns && popups) {
    if (!options.caching) return;

    try {
      if (!window.sessionStorage) return;

      sessionStorage.setItem(KEYS.timestamp, `${new Date().getTime()}`);
      sessionStorage.setItem(KEYS.options, JSON.stringify(options));
      sessionStorage.setItem(KEYS.addOns, JSON.stringify(addOns));
      sessionStorage.setItem(KEYS.popups, JSON.stringify(popups));
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }
}

function isTimedOut() {
  const timestampVal = sessionStorage.getItem(KEYS.timestamp);
  if (timestampVal) {
    const timestamp = parseInt(timestampVal);
    let diff = (new Date().getTime() - timestamp) / 1000;
    diff /= 60;
    const minutes = Math.abs(Math.round(diff));
    return minutes >= TIMEOUT_MINUTES;
  }
}

function clear() {
  sessionStorage.removeItem(KEYS.timestamp);
  sessionStorage.removeItem(KEYS.options);
  sessionStorage.removeItem(KEYS.addOns);
  sessionStorage.removeItem(KEYS.popups);
}

function bustCache() {
  return window.location.href.toLocaleLowerCase().indexOf(CACHE_BUST_KEY) >= 0;
}
