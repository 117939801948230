// om sai ram

import loadScript from './loadScript';
import app from './app';
import errorHandler from './errorHandler';
import { isProductPage, isCartPage } from './utils';
import { isDevMode } from './devMode';
import { removeUpsell } from './renderer';

let loadJquery = false;
if (IS_LOGGING) {
  loadLogging();
}
if (typeof jQuery === 'undefined') {
  loadJquery = true;
} else {
  let ver = 0.0;
  if (jQuery.fn && typeof jQuery.fn.jquery === 'string') {
    ver = parseFloat(
      jQuery.fn.jquery.substring(0, jQuery.fn.jquery.lastIndexOf('.'))
    );
  }

  if (ver >= 2.2) {
    window.jUpsell = jQuery;
    loadApp();
  } else {
    loadJquery = true;
  }
}

if (loadJquery) {
  loadScript(
    '//ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js',
    function() {
      window.jUpsell = jQuery.noConflict(true);
      loadApp();
    }
  );
}

function loadApp() {
  try {
    //createSlider();
    isDevMode();
    if (isProductPage() || isCartPage()) {
      detectVariantChanges();
      app();
    }
  } catch (err) {
    errorHandler(err);
  }
}

function detectVariantChanges() {
  let firstTimeUrl = document.URL;
  document.addEventListener('change', function() {
    let currentPageUrl = document.URL;
    const url = new URL(currentPageUrl);
    const isVariantUrl = url.searchParams.get('variant');
    currentPageUrl = isVariantUrl ? currentPageUrl : isVariantUrl;
    if (currentPageUrl && firstTimeUrl !== currentPageUrl) {
      firstTimeUrl = currentPageUrl;
      removeUpsell();
      app();
    }
  });
}

function loadLogging() {
  loadScript(
    '//browser.sentry-cdn.com/5.6.3/bundle.min.js',
    function() {
      Sentry.init({
        dsn:
          'https://fe5cfc0017ae46c28aa4231ee2669fce@o731358.ingest.sentry.io/5783448',
        environment: 'prod',
        defaultIntegrations: false
      });
    },
    {
      integrity:
        'sha384-/Cqa/8kaWn7emdqIBLk3AkFMAHBk0LObErtMhO+hr52CntkaurEnihPmqYj3uJho',
      crossOrigin: 'anonymous'
    }
  );
}
