import { isCartPage, isRenderCartAddons, isThemeEditor } from '../../utils';
import { APP_BLOCK_CONTAINER } from './utils';

const ID_REPLACE = 'ID_TEXT_REPLACE';

const texts = {
  default: 'Upsell add-ons will be here',
  ultimate: 'Ultimate feature only. Upgrade Now.'
};

const css = `
    <style>
        .${APP_BLOCK_CONTAINER}:empty {
            display: block !important; /* Show in all themes */
            background: aliceblue;
            padding: 40px;
            text-align: center;
        }
        
        .${APP_BLOCK_CONTAINER}:empty:after {
            content: '${ID_REPLACE}';
        }
    </style>
  `;

export default function injectThemeEditorStyles() {
  if (!isThemeEditor()) {
    return;
  }

  const cartVisible = isRenderCartAddons();

  let styles = css;

  if (isCartPage()) {
    styles = styles.replace(
      ID_REPLACE,
      cartVisible ? texts.default : texts.ultimate
    );
  } else {
    styles = styles.replace(ID_REPLACE, texts.default);
  }

  document.querySelector('head').insertAdjacentHTML('beforeend', styles);
}
