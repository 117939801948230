import { addOnAddToCartActionHander } from '../cartActionHandler';
import { cartDetailsUrl } from '../currentCart';
import {
  getAppProps,
  getProductHandleFromRelativeUrl,
  isRenderCartAddons
} from '../utils';
import {
  createAddOnRecord,
  getAddOns,
  sortAddOns,
  wrapAddOns
} from '../addOnsUtil';
import getCollectionAddOns from '../collectionAddOns';
import { PAGE } from '../domain';
import getCatalogAddOns from '../catalogAddOns';
import renderer, { removeUpsell, renderPlaceholder } from '../renderer';
import errorHandler from '../errorHandler';
import { setPopUpData } from './productPageInjector/utils';

function getPopUpAddonsAll() {
  const { popups } = getAppProps();

  const addOns = [];

  for (const collection in popups) {
    const itemsPerCollection = popups[collection];
    for (const key in itemsPerCollection) {
      const parsed = createAddOnRecord(itemsPerCollection, key);
      if (parsed.cartPageVisible) {
        const [productId, variantId] = collection.split('|');
        parsed.targetProductId = parseInt(productId);
        if (variantId) {
          parsed.targetVariantId = parseInt(variantId);
        }
        addOns.push(parsed);
      }
    }
  }

  return addOns;
}

function getPopUpAddons(cartItems) {
  const { options } = getAppProps();
  const allAddonsUsed = getPopUpAddonsAll();

  const allTargetAddons = [];

  // Get addons attached to cart items.
  allAddonsUsed.forEach(addOn => {
    const targetProductId = addOn.targetProductId;
    const targetVariantId = addOn.targetVariantId;

    let founded = false;

    if (targetProductId && targetVariantId) {
      if (
        cartItems.find(
          cartItem =>
            cartItem.product_id === targetProductId &&
            cartItem.variant_id === targetVariantId
        )
      ) {
        founded = true;
      }
    } else {
      if (cartItems.find(cartItem => cartItem.product_id === targetProductId)) {
        founded = true;
      }
    }

    if (founded) {
      allTargetAddons.push(addOn);
    }
  });

  // Get addons is not used in cart
  const allAddons = [];

  allTargetAddons.forEach(addOn => {
    const handle = addOn.handle;
    const id = addOn.ids[0];
    if (
      !cartItems.find(
        cartItem => cartItem.id === id && cartItem.handle === handle
      )
    ) {
      allAddons.push(addOn);
    }
  });

  const addOnsInCart = {};

  for (let i = 0; i < allAddons.length; i++) {
    const product = allAddons[i];
    const handle = product.handle;
    const variantId = product.ids[0];

    //if we are treating variants as individual items
    // then use variantId as a tracker otherwise use product handle
    if (options.groupVariants) {
      if (!addOnsInCart[handle]) {
        addOnsInCart[handle] = product;
      } else {
        addOnsInCart[handle].ids.push(variantId);
      }
    } else {
      addOnsInCart[variantId] = product;
    }
  }

  const addOns = [];

  for (const iter in addOnsInCart) {
    const addOn = addOnsInCart[iter];
    addOns.push(addOn);
  }

  return sortAddOns(addOns);
}

export default function cartPage() {
  const { options, addOnsDb } = getAppProps();

  let renderCartAddons = isRenderCartAddons();

  try {
    addOnAddToCartActionHander(true, options);

    jUpsell
      .getJSON(cartDetailsUrl, function(result) {
        try {
          const addOnsInCart = {};
          const addOns = [];
          const addOnsTracker = {};
          if (result.items) {
            if (renderCartAddons) {
              for (let i = 0; i < result.items.length; i++) {
                const product = result.items[i];
                const handle = getProductHandleFromRelativeUrl(product.url);
                //if we are treating variants as individual items
                // then use variantId as a tracker otherwise use product handle
                if (options.groupVariants) {
                  addOnsInCart[handle] = product;
                } else {
                  addOnsInCart[product.variant_id] = product;
                }
              }
              const groupVairantsTracker = options.groupVariants ? {} : null;
              if (!options.disableCartPageProductAddOns) {
                for (let i = 0; i < result.items.length; i++) {
                  const product = result.items[i];
                  const productAddOns = getAddOns(
                    function() {
                      return wrapAddOns(
                        addOnsDb,
                        product.product_id,
                        product.variant_id
                      );
                    },
                    addOnsDb,
                    addOnsTracker,
                    addOnsInCart,
                    groupVairantsTracker
                  );
                  addOns.push.apply(addOns, productAddOns);
                  const collectionAddOns = getCollectionAddOns(
                    PAGE.Cart,
                    product.product_id,
                    addOnsDb,
                    addOnsTracker,
                    addOnsInCart,
                    groupVairantsTracker
                  );
                  addOns.push.apply(addOns, collectionAddOns);
                }
              }
              const allProductAddOns = getCatalogAddOns(
                addOnsDb,
                addOnsTracker,
                addOnsInCart,
                groupVairantsTracker
              );
              addOns.push.apply(addOns, allProductAddOns);
              if (groupVairantsTracker) {
                for (let groupedVariant in groupVairantsTracker) {
                  addOns.push(groupVairantsTracker[groupedVariant]);
                }
              }
            }
            const popupAddOns = getPopUpAddons(result.items);
            if (popupAddOns.length > 0) {
              setPopUpData({
                product: null, // product exists only in /products/ page
                addOns: popupAddOns
              });
            } else {
              setPopUpData(null);
            }
            const sortedAddOns = sortAddOns(addOns);
            if (sortedAddOns.length > 0) {
              renderPlaceholder(PAGE.Cart);
            }
            renderer(sortedAddOns, popupAddOns, PAGE.Cart);
          }
        } catch (err) {
          removeUpsell();
          errorHandler(err);
        }
      })
      .fail(function(xhr) {
        console.log(
          `errors in fetching ${cartDetailsUrl}, StatusCode:${xhr.status}`
        );
      });
  } catch (err) {
    removeUpsell();
    errorHandler(err);
  }
}
