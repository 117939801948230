import showError from '../../../utils/error';
import { getAppProps, isProductPage } from '../../../utils';
import showInfo from '../../../utils/info';
import { getCartDrawersForm } from '../../../render/common/utils';

class Wrapper {
  constructor(args = {}) {
    const { options } = getAppProps();
    this.options = options;
    this.useContinueText = args.useContinueText || false;
  }

  _addItemToCartFinal(form, contentType) {
    const { options } = getAppProps();
    const self = this;

    // eslint-disable-next-line no-undef
    fetch('/cart/add.js', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': contentType,
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: form
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        if (json.status && json.status !== 200) {
          showError(json, self.options, json.description);
          return;
        }

        let data = {};
        try {
          data = JSON.parse(form);
        } catch (e) {
          console.log('err', e);
        }
        self.onAddToCart(data);

        // Use refresh page for some clients
        if (isProductPage()) {
          let reload = false;

          if (getCartDrawersForm()) {
            reload = true;
          }

          if (options.overridePopUpRefresh) {
            reload = true;
          }

          if (reload) {
            // eslint-disable-next-line no-undef
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      })
      .catch(function(error) {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  onAddToCart() {
    const { options } = getAppProps();

    showInfo('Added to cart', options);
  }

  onEmpty() {
    // Called if items is not selected (implemented in cart hook)
  }

  isUseContinueText() {
    return this.useContinueText;
  }
}

export default Wrapper;
