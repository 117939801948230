import { setMouseLoading } from '../utils';
import axios from 'axios';
import { redirectCheckoutPage } from '../utils/redirect';
import { getShopName } from '../utils/shopify';
import { getItemDiscount, getItemFromAnyAddon } from '../utils/item';

export function discountApi(items) {
  const shopname = getShopName(true);
  const discounts = [];
  const variants = [];
  const products = [];
  const nonDiscountItems = [];

  items.forEach(v => {
    const { product_id, variant_id, id, quantity, type = 'addon' } = v;

    const discount = getItemDiscount(v);

    if (discount > 0) {
      const info = getItemFromAnyAddon(v);

      discounts.push({
        id,
        quantity,
        type,
        productId: product_id.toString(),
        variantId: variant_id.toString(),
        target: {
          productId: info.productId,
          variantId: info.variantId
        }
      });
    } else {
      nonDiscountItems.push({
        id,
        quantity,
        productId: product_id.toString(),
        variantId: variant_id.toString()
      });
    }

    variants.push(variant_id.toString());
    products.push(product_id.toString());
  });

  if (discounts.length > 0) {
    setMouseLoading(true);
    axios
      .post(
        DISCOUNT_API_URL,
        {
          variants,
          products,
          items: discounts,
          nonDiscountItems,
          shop: shopname
        },
        { timeout: 10 * 1000 } // timeout 10 secs -> catch block.
      )
      .then(res => {
        setMouseLoading(false);

        const { error, errCode, result } = res.data;
        if (error) {
          console.log('err', errCode);
          // Redirect without discount.
          redirectCheckoutPage();
          return;
        }

        // Redirect to new our url checkout
        window.location.href = result.url;
      })
      .catch(err => {
        setMouseLoading(false);

        console.log('err', err);
        // Redirect without discount.
        redirectCheckoutPage();
      });
  } else {
    setMouseLoading(false);

    redirectCheckoutPage();
  }
}
