import {
  getAppProps,
  getMainProductQuantity,
  getMainProductVariant,
  setMouseLoading
} from '../../utils';
import { getCartItems } from '../../api/common';
import { discountApi } from '../../api/discount';
import { ENABLED_DISCOUNTS } from '../../constants';
import { redirectCheckoutPage } from '../../utils/redirect';
import { showDefaultError } from '../../utils/error';

async function callDiscountAPI() {
  // Init animation due to get cart items
  setMouseLoading(true);

  const cartItems = await getCartItems();
  const items = [];

  // Prepare items
  cartItems.forEach(item => {
    items.push({
      product_id: item.product_id,
      variant_id: item.variant_id,
      id: item.id,
      quantity: item.quantity,
      type: 'addon'
    });
  });

  discountApi(items);
}

export function callNextStep() {
  if (ENABLED_DISCOUNTS) {
    callDiscountAPI();
  } else {
    redirectCheckoutPage();
  }
}

export function callEmptyNextStep() {
  // Init animation due to get cart items
  setMouseLoading(true);

  // Add main item
  const quantity = getMainProductQuantity();
  const variantId = getMainProductVariant();

  fetch('/cart/add.js', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify({
      items: [
        {
          id: parseInt(variantId),
          quantity
        }
      ]
    })
  })
    .then(() => {
      // Call all next steps
      callNextStep();
    })
    .catch(err => {
      const { options } = getAppProps();

      showDefaultError('Error occurred!', options);
    });
}
