import { getPriceDetails, getAddOnProduct } from './utils';

export function adjustProductPrice(selectedAddons, options) {
  if (!options.priceSelector) return;
  let addOnPrices = 0;
  for (let addOnId in selectedAddons) {
    addOnPrices += getAddOnPrice(addOnId, selectedAddons[addOnId]);
  }
  const newPrice = getProductPrice() + addOnPrices;
  updatePriceElement(options, newPrice);
}

function updatePriceElement(options, newPrice) {
  const priceDetails = getPriceDetails(options, newPrice);
  const priceElement = document.querySelector(options.priceSelector);
  if (priceElement) {
    priceElement.textContent = priceDetails.price;
  } else {
    console.warn(`priceElement ${options.priceSelector} not found!`);
  }
}

function getProductPrice() {
  if (window.location.search.toLowerCase().indexOf('variant') >= 0) {
    const queryStrings = window.location.search.toLowerCase().split('&');
    const selectedVariantId = queryStrings
      .find(function(queryString) {
        return queryString.indexOf('variant') >= 0;
      })
      .split('=')[1];
    const selectedVariant = window.upsell.product.details.variants.find(
      function(variant) {
        return variant.id === parseInt(selectedVariantId);
      }
    );
    return selectedVariant ? selectedVariant.price / 100 : 0;
  } else {
    return window.upsell.product.details.variants[0].price / 100;
  }
}

function getAddOnPrice(variantId, qty) {
  const selectedAddOn = getAddOnProduct(variantId);

  if (!qty) {
    qty = 1;
  }
  return parseFloat(selectedAddOn.price) * qty;
}
