import errorHandler from './errorHandler';
import { getAppProps } from './utils';
const NO_IMAGE = null;

export function prepareAddonDetails(args) {
  const { options, result, addOnRecord } = args;
  const ids = addOnRecord.ids;
  let details = null;
  const variants = result.product.variants;
  if (ids.length > 1 && variants.length > 1) {
    details = getDetailsForGroupedVariants(ids, result.product, options);
  } else if (variants.length > 1 && ids.length === 1) {
    details = getSingleVariantDetails(ids[0], result.product, options);
  } else {
    details = {};
    details.id = variants[0].id;
    details.title = result.product.title;
    details.price = variants[0].price;
    details.compareAtPrice = variants[0].compare_at_price;
    details.image = result.product.image ? result.product.image.src : NO_IMAGE;
    details.url = getUrl(result.product.handle, null, options);
    details.originalSmallImage = getOriginalImageUrl(details.image);
    details.productId = result.product.id;
  }
  if (addOnRecord.order) {
    details.order = addOnRecord.order;
  }
  return details;
}

export default function getAddOnDetails(addOnRecord) {
  const { handle: slug } = addOnRecord;
  return new Promise(function(resolve, reject) {
    const { options } = getAppProps();
    const addOnDetailsUrl = IS_SHOPIFY_ENV
      ? `/products/${slug}.json`
      : '/data/' + slug + '.json';
    jUpsell
      .getJSON(addOnDetailsUrl, function(result) {
        try {
          const details = prepareAddonDetails({
            result,
            options,
            addOnRecord
          });
          resolve(details);
        } catch (err) {
          errorHandler(err);
          reject(null);
        }
      })
      .fail(function(xhr) {
        console.log(
          `errors in fetching ${addOnDetailsUrl}, StatusCode:${xhr.status}`
        );
        reject(null);
      });
  });
}

function getDetailsForGroupedVariants(ids, product, options) {
  const variantOptions = [];
  const variants = product.variants;
  for (let i = 0; i < variants.length; i++) {
    if (ids.indexOf(variants[i].id) >= 0) {
      const image = getVariantImage(variants[i].id, product);
      let originalSmallImage = getOriginalImageUrl(image);
      variantOptions.push({
        id: variants[i].id,
        title: variants[i].title || variants[i].option1,
        image: image,
        price: variants[i].price,
        compareAtPrice: variants[i].compare_at_price,
        url: getUrl(product.handle, variants[i].id, options),
        originalSmallImage: originalSmallImage,
        quantity: variants[i].inventory_quantity,
        productId: product.id
      });
    }
  }
  if (variantOptions.length > 0) {
    return {
      title: product.title,
      variantOptions: variantOptions,
      variantOptionsLabel:
        product.options && product.options.length > 0
          ? product.options[0].name
          : ''
    };
  }
  return null;
}

function getSingleVariantDetails(id, product, options) {
  const variants = product.variants;
  for (let i = 0; i < variants.length; i++) {
    if (variants[i].id === parseInt(id)) {
      const details = {};
      details.id = variants[i].id;
      details.price = variants[i].price;
      details.compareAtPrice = variants[i].compare_at_price;
      details.title = getProductTitleForSingleVaraint(
        product.title,
        variants[i].title,
        options
      );
      details.image = getVariantImage(variants[i].id, product);
      details.url = getUrl(product.handle, variants[i].id, options);
      details.originalSmallImage = getOriginalImageUrl(details.image);
      details.productId = product.id;
      return details;
    }
  }
  return null;
}

function getOriginalImageUrl(image) {
  if (image) {
    const lastIndex = image.lastIndexOf('.');
    return image.insertAt(lastIndex, '_small');
  }
  return null;
}

function getUrl(handle, variantId, options) {
  const variantFragment = variantId ? `?variant=${variantId}` : '';
  const lang =
    options.hasMultipleLanguages &&
    Shopify.locale &&
    Shopify.locale.toLowerCase() !== 'en'
      ? `${Shopify.locale}/`
      : '';
  return `https://${window.location.hostname}/${lang}products/${handle}${variantFragment}`;
}

function getVariantImage(variantId, productDetails) {
  if (!productDetails.images) return;

  for (let i = 0; i < productDetails.images.length; i++) {
    const image = productDetails.images[i];

    if (image.variant_ids && image.variant_ids.indexOf(variantId) >= 0) {
      return image.src;
    }
  }
  // default image
  return productDetails.image ? productDetails.image.src : NO_IMAGE;
}

function getProductTitleForSingleVaraint(productTitle, variantTitle, options) {
  const varaintPlaceHolder = '%VARAINT_TITLE%';
  const variaintFormat =
    options.productVariantTitleFormat === undefined ||
    options.productVariantTitleFormat === null
      ? ` (${varaintPlaceHolder})`
      : options.productVariantTitleFormat;

  return `${productTitle}${variaintFormat.replace(
    varaintPlaceHolder,
    variantTitle
  )}`;
}
